// import React, { useState, useEffect } from "react";
// import { DatePicker, Button, Typography, Row, Col, Spin, message } from "antd";
// import axios from "axios";
// import config from "../../config";
// import CurrentServicesCard from "../cards/CurrentServicesCard";
// import UserDisplayCard from "../cards/UserDisplayCard";
// import "./Notification.css";
// function Notification({ SelectUserData }) {
//   const [shoptype, setShopType] = useState("Shopping");

//   const [loading, setloading] = useState(true);
//   const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
//   const [UseData, setUserData] = useState([]);
//   const getUserData = async (userId) => {
//     const endpointUrl =
//       LoginUser.ROLE === "0" ? "adminNotification" : "notificationRecieved1";
//     // console.log(LoginUser, ".....");
//     try {
//       const response = await axios.get(`${config.baseURL}${endpointUrl}/`);

//       // Handle the response as needed
//       console.log(response, "................");
//       if (response?.data?.recordset) {
//         console.log("....");
//         const data = response?.data?.recordset;
//         setUserData(data);
//         setloading(false);
//       }
//       return response.data;
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching unassigned details:", error);
//       throw error;
//     }
//   };
//   useEffect(() => {
//     getUserData();
//   }, []);

//   function formatDateToDDMMYY(isoString) {
//     const date = new Date(isoString);

//     // Get day, month, and year
//     const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
//     const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

//     // Return formatted date
//     return `${day}/${month}/${year}`;
//   }
//   const onClick = (data) => {
//     console.log(data, "Onclick data ....");
//     SelectUserData(data);
//   };
//   return (
//     <div className="userProfileMain">
//       <h1 className="heading">Notification</h1>
//       <div className="body-section">
//         {/* <div className="selectedShoptypes">
//           <button
//             className={shoptype === "Shopping" ? "Shopping" : "unShopping"}
//             onClick={() => setShopType("Shopping")}
//           >
//             Shopping
//           </button>
//           <button
//             className={shoptype === "Wardrobe" ? "Wardrobe" : "unWardrobe"}
//             onClick={() => setShopType("Wardrobe")}
//           >
//             Wardrobe
//           </button>
//         </div> */}
//         {!loading ? (
//           <>
//             {UseData.map((user) => (
//               <div className="notify">
//                 <div className="notifybody">
//                   <p className="notiTitle">{user.NOTI_TITLE}</p>
//                   <p>{user.NOTI_BODY}</p>
//                 </div>
//                 <p>Date: {formatDateToDDMMYY(user.NOTI_SENT_DATE)}</p>
//               </div>
//             ))}
//           </>
//         ) : (
//           <div className="loading">
//             <Spin tip="Loading..." style={{ marginTop: "5%" }} />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Notification;



import React, { useState, useEffect } from "react";
import { DatePicker, Button, Typography, Row, Col, Spin, message, Flex } from "antd";
import axios from "axios";
import config from "../../config";
import CurrentServicesCard from "../cards/CurrentServicesCard";
import UserDisplayCard from "../cards/UserDisplayCard";
import "./Notification.css";
function Notification({ SelectUserData }) {
  const [shoptype, setShopType] = useState("Shopping");

  const [loading, setloading] = useState(true);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);
  const getUserData = async (userId) => {
    const endpointUrl =
      LoginUser.ROLE === "0" ? "adminNotification" : "notificationRecieved1";
    // console.log(LoginUser, ".....");
    try {
      const response = await axios.get(`${config.baseURL}${endpointUrl}/`);

      // Handle the response as needed
      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;
        setUserData(data);
        setloading(false)
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  function formatDateToDDMMYY(isoString) {
    const date = new Date(isoString);

    // Get day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    // Return formatted date
    return `${day}/${month}/${year}`;
  }
  const onClick = (data) => {
    console.log(data, "Onclick data ....");
    SelectUserData(data);
  };
  return (
    <div className="userProfileMain">
      <h1 className="heading">Notification</h1>
      <div className="body-section">

        {!loading ? (
          <>
            {/* <Row gutter={[16, 16]}>
              {UseData.map((user) => (
                <Col xs={24} sm={12} md={8} key={user.NOTI_ID}>
                  <div className="notify">
                    <Flex gap={30} vertical className="notifybody">
                      <div className="notiTitle-Div">
                        <div className="notiTitle">{user.NOTI_TITLE}</div>
                      </div>
                      <Flex vertical gap={20} className="Notisub-Div">
                        <div>
                          <p>{user.NOTI_BODY}</p>
                        </div>
                        <Flex vertical gap={20}>
                          <div className="Notification-Content">
                          <p>Date :</p>
                          <div>{formatDateToDDMMYY(user.NOTI_SENT_DATE)}</div>
                          </div>

                         
                            <div className="Notification-Content">
                            <p>Service_Name : </p>
                            <div> {user.SERVICE_ID === '1' ? 'Shopping' : user.SERVICE_ID === '2' ? 'Wardrobe' : user.SERVICE_ID}</div>
                            </div>
                            <div className="Notification-Content"> 
                          <p>Service_Id : </p>
                          <div> {user.SERVICE_ID}</div>
                          </div>
                          <div className="Notification-Content">
                          <p>User_Service_Id :</p> 
                          <div>{user.USER_SERVICE_ID}</div>
                          </div>
                        </Flex>
                      </Flex>
                    </Flex>
                  </div>
                </Col>
              ))}
            </Row> */}

            <Row gutter={[16, 16]}>
              {UseData.map((user) => (
                <Col xs={24} sm={12} md={12} lg={8} key={user.NOTI_ID}>
                  <div className="notify">
                    <Flex gap={30} vertical className="notifybody">
                      <div className="notiTitle-Div">
                        <div className="notiTitle">{user.NOTI_TITLE}</div>
                      </div>
                      <Flex vertical gap={20} className="Notisub-Div">
                        <div>
                          <p>{user.NOTI_BODY}</p>
                        </div>
                        <Flex vertical gap={20} className="Notification-Content-Div">
                          <div className="Notification-Content">
                            <p>Date :</p>
                            <div>{formatDateToDDMMYY(user.NOTI_SENT_DATE)}</div>
                          </div>
                          <div className="Notification-Content">
                            <p>Service_Name :</p>
                            <div>
                              {user.SERVICE_ID === '1' ? 'Shopping' : user.SERVICE_ID === '2' ? 'Wardrobe' : user.SERVICE_ID}
                            </div>
                          </div>
                          {/* <div className="Notification-Content">
                            <p>Service_Id :</p>
                            <div>{user.SERVICE_ID}</div>
                          </div>
                          <div className="Notification-Content">
                            <p>User_Service_Id :</p>
                            <div>{user.USER_SERVICE_ID}</div>
                          </div> */}
                        </Flex>
                      </Flex>
                    </Flex>
                  </div>
                </Col>
              ))}
            </Row>

          </>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Notification;
