import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/DashboardLayout';
import {requestFCMToken} from './Components/firebase/firebaseUtiles';
import BlogScraper from './checking code/BlogScraper';
const App = () => {
  const [fcmToken,setfcmToken]=useState(null);
  useEffect(()=>{
    const fetchFCMToken = async ()=>{
      try {
        const token =await requestFCMToken();
        setfcmToken(token)
        sessionStorage.setItem("fcmToken",token)
        // console.log(token)
      }catch (err) {
        console.log("Error getting FCM Token :",err)
      }
    }

    fetchFCMToken();
  })
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} /> 
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </Router>

    // <>
    // <div className="App">
    //   <BlogScraper />
    // </div>
    // </>
  );
};

export default App;
