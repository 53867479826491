import React, { useState, useEffect } from "react";
import { DatePicker, Button, Typography, Row, Col, Spin, message } from "antd";
import axios from "axios";
import config from "../../config";
import CurrentServicesCard from "../cards/CurrentServicesCard";
import UserDisplayCard from "../cards/UserDisplayCard";
import "../../pages/css/UserProfile.css";
import UserProfile from "../../pages/UserProfile";
function Servicehistory() {
  const [shoptype, setShopType] = useState("Shopping");
  const [showDetails, setshowDetails] = useState(true);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);
  const [loading, setloading] = useState(true);
  const [selectviewuser, setselectviewuser] = useState({});

  const getUserData = async (userId) => {
    const endpointUrl =
      LoginUser.ROLE === "0"
        ? "getClosedConnections"
        : "getClosedConnectionsStylist";
    // console.log(LoginUser, ".....");
    try {
      const response = await axios.get(
        `${config.baseURL}${endpointUrl}/${LoginUser?.USER_ID}`
      );

      // Handle the response as needed
      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;
        setUserData(data);
        setloading(false);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  const onClick = (data) => {
    console.log(data, "Onclick data ....");
    setselectviewuser(data);
    setshowDetails(false);
  };
  return (
    <>
      {showDetails ? (
        <div className="userProfileMain">
          {LoginUser.ROLE === "0" ? (
            <h1 className="heading">History</h1>
          ) : (
            <h1 className="heading">Service History</h1>
          )}

          <div className="body-section">
            {!loading ? (
              <>
                {UseData.map((user) => (
                  <UserDisplayCard
                    key={user.user_id}
                    image={user.user_photo}
                    name={user.first_name}
                    dob={user.age}
                    user={user}
                    background={`${
                      user?.service_category === "Digital Wardrobe"
                        ? "#FBF1E9"
                        : "#ffffbc"
                    }`}
                    // background={`${
                    //   user?.service_category === "Digital Wardrobe"
                    //     ? "#FBF1E9"
                    //     : "#ffffbc"
                    // }`}
                    buttonText={"View"}
                    onClick={() => onClick(user)}
                  />
                ))}
              </>
            ) : (
              <div className="loading">
                <Spin tip="Loading..." style={{ marginTop: "5%" }} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <UserProfile
            SelectUserData={selectviewuser}
            setBack={() => setshowDetails(!showDetails)}
            servicetype={"view"}
          />
        </>
      )}
    </>
  );
}

export default Servicehistory;
