import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { Spin } from "antd";
import QuestionAnswerComponent from "../cards/QuestionAnswerComponent";
import "./css/UserQuestions.css";
const UserQuestions = ({ SelectUserData }) => {
  // console.log(SelectUserData, "SelectUserData....");
  const [userPersonalQuestionsData, setUserPersonalQuestionsData] = useState(
    []
  );
  const [userShoppingQuestionsData, setUserShoppingQuestionsData] = useState(
    []
  );
  const [loadingPersonal, setLoadingPersonal] = useState(true);
  const [loadingShopping, setLoadingShopping] = useState(true);
  const Type= SelectUserData?.service_category==="Online Shopping"? "Shopping":"Wardrobe";

  const getUserPersonalQuestionsData = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/PERSONAL`
      );
      if (response?.data?.recordset) {
        setUserPersonalQuestionsData(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching personal questions:", error);
    } finally {
      setLoadingPersonal(false);
    }
  };

  const getUserShoppingQuestionsData = async (userId) => {
    try {


      const endpoint= SelectUserData?.service_category==="Online Shopping"? "SHOPPING_ASSISTANT":"WARDROBE_ASSISTANT";
      // console.log(SelectUserData,"SelectUserData......",endpoint)
      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/${endpoint}`
      );
      if (response?.data?.recordset) {
        // console.log(response?.data?.recordset,"response?.data?.recordset.....")
        setUserShoppingQuestionsData(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching shopping questions:", error);
    } finally {
      setLoadingShopping(false);
    }
  };

  useEffect(() => {
    if (SelectUserData?.user_id) {
      getUserPersonalQuestionsData(SelectUserData.user_id);
      getUserShoppingQuestionsData(SelectUserData.user_id);
    }
  }, [SelectUserData]);

  return (
    <div className="userQuestionMainDiv">
      <div className="userQuestionDiv">
        {/* <h1>Personal Questions</h1> */}
        <div className="userProfileMain">
          <h1 className="heading ">Personal Questions</h1>
        </div>
        {loadingPersonal ? (
          <Spin tip="Loading..." style={{ marginTop: "5%" }} />
        ) : userPersonalQuestionsData.length > 0 ? (
          userPersonalQuestionsData.map((user, index) => (
            <QuestionAnswerComponent
              key={index}
              question={user.QUESTION}
              answer={user.USER_ANSWERS}
            />
          ))
        ) : (
          <p>No personal questions found.</p>
        )}
      </div>
      <div className="userQuestionDiv">
        <div className="userProfileMain">
          <h1 className="heading">{Type} Assistant Questions</h1>
        </div>
        {loadingShopping ? (
          <Spin tip="Loading..." style={{ marginTop: "5%" }} />
        ) : userShoppingQuestionsData.length > 0 ? (
          userShoppingQuestionsData.map((user, index) => (
            <QuestionAnswerComponent
              key={index}
              question={user?.QUESTION}
              answer={user?.USER_ANSWERS}
            />
          ))
        ) : (
          <p>No {Type} questions found.</p>
        )}
      </div>
    </div>
  );
};

export default UserQuestions;
