import React, { useState, useEffect } from "react";
import CarouselComponent from "../Components/login/Carousel";
import LoginForm from "../Components/login/LoginForm.js";
import "../Components/login/LoginForm.css";
import config from "../config.jsx";
import axios from "axios";
import logo from "../images/logo.svg";
const LoginPage = () => {
  // const [logo, setlogo] = useState("");
  // const fetchUserData = async () => {
  //   try {
  //     const response = await axios.get(`${config.baseURL}getStylistList`);
  //     console.log(response.data.recordset, "reeeeeeee..........");
  //     setlogo(response.data.recordset);
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchUserData();
  // }, []);
  return (
    <>
      <div style={{
            position: "absolute",
            margin: "33px",
      }}>
        {" "}
        <img src={logo} style={{width:'11rem'}} />
      </div>

      <div className="login-page-container">
        {/* <div>
        {" "}
      <img src={logo}/>
        <h2>STYLE</h2>
      </div> */}
        <div className="carousel-side">
          <CarouselComponent />
        </div>
        <div className="form-side">
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
