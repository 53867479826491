import ProfileForm from "../Components/profile/profilecard";


const Profile = () => {
    return (
        <>

            <div className="userProfileMain">
                <h1 className="heading">Profile</h1>
                <div>
                    <ProfileForm />
                </div>
            </div>
        </>
    )
}

export default Profile;