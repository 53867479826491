import React from 'react';
import config from '../../config';
import './css/CollageShoppingComponent.css'
const CollageShoppingComponent = ({ brand, imageURL, name, price }) => {
  return (
    <div className="CollageShoppingComponent">
      <img className="img" src={`${config.imageUrl}/${imageURL}`} alt={name} />
      <div className="px-6 py-4">
       
        <p className="name">
          {name}
        </p>
        <p className="price">
          ₹{price !== 'N/A' ? price : 'Price not available'} {brand}
        </p>
        {/* <div className="brand">{brand}</div> */}
      </div>
    </div>
  );
};

export default CollageShoppingComponent;
