import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";

const StyleSearch = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");
  const [UserStyleSearchData, setUserStyleSearchData] = useState([]);

  const getUserStyleSearchData = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/SHOPPING_ASSISTANT`
      );

      // Handle the response as needed
      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;

        setUserStyleSearchData(data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  useEffect(() => {
    // getUserStyleSearchData(SelectUserData?.user_id);
  }, []);
  return <div>Coming Soon.....</div>;
};

export default StyleSearch;
