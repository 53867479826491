// import React, { useState, useEffect } from 'react';
// import { Drawer, Button, Input, Upload, message } from 'antd';
// import { MessageOutlined, UploadOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import Profile from '../../images/profile.jpg';
// import config from "../../config";
// import './chatboat.css';

// const { TextArea } = Input;

// const Chatbot = ({ onButtonClick, setBack, SelectUserData }) => {
//   const [visible, setVisible] = useState(false);
//   const [userInput, setUserInput] = useState('');
//   const [fileList, setFileList] = useState([]);
//   const [chatHistory, setChatHistory] = useState([]);

//   const showDrawer = () => {
//     setVisible(true);
//   };

//   const onClose = () => {
//     setVisible(false);
//   };

//   const Type = SelectUserData?.service_category === "Online Shopping" ? "Shopping" : "Wardrobe";

// //   useEffect(() => {
// //     if (SelectUserData?.user_service_id) {
// //       axios.get(`${config.baseURL}getChat/${SelectUserData.user_service_id }`)
// //         .then(response => {
// //             console.log(response,'response...........')
// //           setChatHistory(response.data);
// //         })
// //         .catch(error => {
// //           console.error("There was an error fetching the chat history!", error);
// //         });
// //         const intervalId = setInterval(fetchChatHistory, 2000);

// //         // Clean up the interval on component unmount
// //         return () => clearInterval(intervalId);
// //     }
// //   }, [SelectUserData]);

// const fetchChatHistory = () => {
//     if (SelectUserData?.user_service_id) {
//       axios.get(`${config.baseURL}getChat/${SelectUserData.user_service_id}`)
//         .then(response => {
//           console.log(response, 'response...........');
//           setChatHistory(response.data);
//         })
//         .catch(error => {
//           console.error("There was an error fetching the chat history!", error);
//         });
//     }
//   };

//   useEffect(() => {
//     if (SelectUserData?.user_service_id) {
//       fetchChatHistory(); // Fetch chat history immediately

//       // Set up interval to fetch chat history every 2 seconds
//       const intervalId = setInterval(fetchChatHistory, 2000);

//       // Clean up the interval on component unmount
//       return () => clearInterval(intervalId);
//     }
//   }, [SelectUserData]);

// console.log(SelectUserData,'SelectUserData...........')

//   const handleInputChange = (e) => {
//     setUserInput(e.target.value);
//   };

//   const handleSubmit = () => {
//     const formData = new FormData();
//     formData.append('text', userInput);
//     formData.append('userId', SelectUserData?.id);

//     fileList.forEach((file) => {
//       formData.append('files', file.originFileObj);
//     });

//     axios.post(`${config.apiUrl}/sendMessage`, formData)
//       .then(response => {
//         message.success('Message sent successfully!');
//         setChatHistory([...chatHistory, response.data]); // Update chat history
//         setUserInput(''); // Clear input
//         setFileList([]); // Clear file list
//       })
//       .catch(error => {
//         console.error("There was an error sending the message!", error);
//         message.error('Failed to send the message.');
//       });
//   };

//   const handleFileChange = ({ fileList }) => setFileList(fileList);

//   return (
//     <div>
//       <Button
//         type="primary"
//         icon={<MessageOutlined />}
//         onClick={showDrawer}
//       >
//       </Button>

//       <Drawer
//         title={
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <img
//               src={`${SelectUserData.user_photo ? `${config.imageUrl}${SelectUserData.user_photo}` : Profile}`}
//               alt="User"
//               className="Chat-header-img"
//             />
//             <span style={{ flex: 1 }}>{SelectUserData?.first_name}</span>
//           </div>
//         }
//         placement="right"
//         onClose={onClose}
//         visible={visible}
//         width={300}
//       >
//         {/* Display chat history */}
//         {chatHistory.map((chat, index) => (
//           <p key={index}>{chat.message}</p>
//         ))}

//         <TextArea
//           rows={2}
//           value={userInput}
//           onChange={handleInputChange}
//           placeholder="Type your message here..."
//         />

//         <Upload
//           fileList={fileList}
//           onChange={handleFileChange}
//           beforeUpload={() => false} // Prevent automatic upload
//           multiple={true}
//         >
//           <Button icon={<UploadOutlined />}>Upload Image</Button>
//         </Upload>

//         {/* Submit button */}
//         <Button type="primary" onClick={handleSubmit} style={{ marginTop: 10 }}>
//           Send
//         </Button>
//       </Drawer>
//     </div>
//   );
// };

// export default Chatbot;

import React, { useState, useEffect } from "react";
import { Drawer, Button, Input, Upload, message } from "antd";
import {
  MessageOutlined,
  UploadOutlined,
  CameraOutlined,
  SendOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Profile from "../../images/profile.jpg";
import config from "../../config";
import "./chatboat.css";
import moment from "moment";

const { TextArea } = Input;

const Chatbot = ({ onButtonClick, setBack, SelectUserData, onClosebutton }) => {
  const [visible, setVisible] = useState(true);
  const [userInput, setUserInput] = useState("");
  const [fileList, setFileList] = useState([]);

  const fcmToken = sessionStorage.getItem("fcmToken");
  const [chatHistory, setChatHistory] = useState([]);

  // const showDrawer = () => {
  //     setVisible(true);
  // };

  const onClose = () => {
    setVisible(false);
    onClosebutton(false);
  };

  const Type =
    SelectUserData?.service_category === "Online Shopping"
      ? "Shopping"
      : "Wardrobe";
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);

    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }
  const fetchChatHistory = () => {
    if (SelectUserData?.user_service_id) {
      axios
        .get(`${config.baseURL}getChat/${SelectUserData.user_service_id}`)
        .then((response) => {
          console.log(response, "response...........");
          setChatHistory(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the chat history!", error);
        });
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Handle case where dateString might be null or undefined
 
    const date = new Date(dateString);
 
    // Check if the date is valid
    if (isNaN(date.getTime())) return 'Invalid Date';
 
    // Array of month abbreviations
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
    const day = date.getDate();
    const month = monthNames[date.getMonth()]; // Get the 3-letter month abbreviation
    const year = date.getFullYear();
 
    // Format the date as Day 3-Letter Month, Year
    return `${day < 10 ? '0' : ''}${day} ${month}, ${year}`;
  };
  // useEffect(() => {
  //     if (SelectUserData?.user_service_id) {
  //         fetchChatHistory(); // Fetch chat history immediately

  //         // Set up interval to fetch chat history every 2 seconds
  //         const intervalId = setInterval(fetchChatHistory, 2000);

  //         // Clean up the interval on component unmount
  //         return () => clearInterval(intervalId);
  //     }
  // }, [SelectUserData]);

  useEffect(() => {
    let intervalId;
    if (visible && SelectUserData?.user_service_id) {
      fetchChatHistory(); // Fetch chat history immediately
      intervalId = setInterval(fetchChatHistory, 2000); // Set up interval to fetch chat history every 2 seconds
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval when component unmounts or when chat window is closed
    };
  }, [visible, SelectUserData]);

  // console.log(SelectUserData, "SelectUserData...........");
  const ChatNotify = async (chatText) => {
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Your Service has been Closed",
          body: chatText,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    let chatText = "";
    let type = "";
    let images = "";

    if (fileList.length > 0) {
      // Assuming that only one file is uploaded
      formData.append("picture", fileList[0].originFileObj);
      chatText = "IMAGES";
      type = "IMAGE";
      images = "IMAGES";
    } else {
      chatText = userInput;
      type = "TEXT";
      images = "";
    }

    const data = {
      CHAT_ID: 0,
      USER_ID: SelectUserData?.id,
      SERVICE_ID: SelectUserData?.service_id,
      CHAT: chatText,
      TYPE: type,
      USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
      STYLIST_ID: SelectUserData?.stylist_id,
      IMAGES: images,
      // 'AUDIO' : AUDIO, // Add this if AUDIO is needed
    };

    formData.append("data", JSON.stringify(data));

    try {
      const response = await axios.post(
        `${config.baseURL}updateChatting`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // timestamp: new Date()
        }
      );

      message.success("Message sent successfully!");
      ChatNotify(chatText);
      setChatHistory([...chatHistory, response.data]); // Update chat history
      setUserInput(""); // Clear input
      setFileList([]); // Clear file list
    } catch (error) {
      console.error("There was an error sending the message!", error);
      message.error("Failed to send the message.");
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    // Get hours and minutes
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time
    hours = hours % 12 || 12; // Convert 0 hours to 12
    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes} ${period}`;
  };
  return (
    <div>
      {/* <Button
                type="primary"
                icon={<MessageOutlined />}
                onClick={showDrawer}
            >
            </Button> */}

      <Drawer
        title={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`${
                  SelectUserData.user_photo
                    ? `${config.imageUrl}${SelectUserData.user_photo}`
                    : Profile
                }`}
                alt="User"
                className="Chat-header-img"
              />
              <span style={{ flex: 1 }}>{SelectUserData?.first_name}</span>
            </div>
            <div>
              {/* <span style={{ flex: 1 }}>{SelectUserData?.start_date
                    }</span> */}
              {/* <span style={{ flex: 1 }}>
                {SelectUserData?.start_date
                  ? formatDate(SelectUserData.start_date)
                  : "No date available"}
              </span> */}
            </div>
          </>
        }
        placement="right"
        onClose={() => onClose(false)}
        visible={visible}
        width={450}
      >
        {/* Display chat history */}
        <div className="chat-container">
          {console.log(chatHistory, "chatHistory..........R")}
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={chat.CUSTOMER_ID ? "their-message" : "my-message"}
            >
              {chat.TYPE === "IMAGE" && (
                <img
                  src={extractBaseUrl(`${config.imageUrl}${chat.IMAGES}`)}
                  alt="Chat"
                />
              )}
              <div className="Chat-Ptag">
                {chat.TYPE !== "IMAGE" && <p style={{}}>{chat.CHAT}</p>}

                <span style={{ fontSize: "0.8em", color: "#888" }}>
                  {/* {moment(chat.CREATED_DATE).format('h:mm A')} */}
                  {getTimeFromTimestamp(chat.CREATED_DATE)}
                </span>
              </div>
              {/* {chat.TYPE === 'IMAGE' && <img src={`${config.imageUrl}${chat.IMAGES}`} alt="Chat" />} */}
            </div>
          ))}
        </div>
        <div className="Chat-Bottom-content">
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            multiple={true}
          >
            <Button icon={<CameraOutlined />}></Button>
          </Upload>
          <Input
            rows={2}
            value={userInput}
            onChange={handleInputChange}
            placeholder="Type your message here..."
          />

          {/* Submit button */}
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginTop: 10 }}
          >
            <SendOutlined />
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default Chatbot;
