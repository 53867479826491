// src/components/Popup.js
import React ,{useEffect} from 'react';
import './css/Popup.css'; // Import your CSS file
import axios from "axios";
import config from "../../config";
const Popup = ({ namesList, onClose ,onStylzClick}) => {
 
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2>Assigned To</h2>
        <ul>
          {namesList.map((item) => (
            <li key={item.id} onClick={() => onStylzClick(item)}>
              {item.FIRST_NAME}
            </li>
          ))}
        </ul>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
