import React, { useState } from "react";
import axios from "axios";
import { Input, Button, Form, Row, Col, message } from "antd";
import "./css/Changepassword.css"; // Import the external CSS
import config from "../config";
const Changepassword = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");

  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const handleSubmit = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      setError("New Password and Confirm Password do not match.");
      return;
    }
    console.log(values, "ldsildn");
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        USER_ID: LoginUser?.USER_ID,
        PASSWORD: values.oldPassword,
        NEW_PASSWORD: values.newPassword,
      })
    );

    try {
      const response = await axios.post(
        `${config.baseURL}changepasswordUser`,
        {
          USER_ID: LoginUser?.USER_ID,
          PASSWORD: values.oldPassword,
          NEW_PASSWORD: values.newPassword,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      if (response.status === 200) {
        message.success("Password changed successfully!");
        form.resetFields(); // Reset form after successful submission
        setError("");
      }
    } catch (error) {
      setError("Failed to change password. Please try again.");
      message.error("Failed to change password.");
    }
  };

  return (
    <div className="changepassword">
      <div className="userProfileMain">
        <h1 className="heading">Change Password</h1>
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="change-password-form"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                { required: true, message: "Please enter your old password" },
              ]}
            >
              <Input.Password
                placeholder="Enter old password"
                className="input-field"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please enter your new password" },
              ]}
            >
              <Input.Password
                placeholder="Enter new password"
                className={`input-field ${error ? "error" : ""}`}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please confirm your new password" },
              ]}
            >
              <Input.Password
                placeholder="Confirm new password"
                className={`input-field ${error ? "error" : ""}`}
              />
            </Form.Item>
          </Col>

          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="button"
              htmlType="submit"
              block
              style={{ width: "105px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Row>

        {error && <p className="message error">{error}</p>}
      </Form>
    </div>
  );
};

export default Changepassword;
