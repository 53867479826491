import Manageusercard from "../Components/manageuser/manageusercard";


const Manageuser = () => {
    return (
        <>

            <div className="userProfileMain">
                <h1 className="heading">Create Stylz</h1>
                <div>
                    <Manageusercard />
                </div>
            </div>
        </>
    )
}

export default  Manageuser ;