import React, { useState, useEffect } from "react";
import { Spin, Flex, message } from "antd";
import axios from "axios";
import config from "../../config";
import {
  LinkOutlined,
  AppstoreOutlined,
  AppstoreFilled,
} from "@ant-design/icons";
import CollageComponent from "../cards/CollageComponent";
import ImageUploadAndPreview from "./ImageUploadAndPreview";
const WardrobeCollage = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(true);
  const [collageList, setcollageList] = useState([]);
  const [UserImagesData, setUserImagesData] = useState([]);
  const [uploadDragImageList, setuploadDragImageList] = useState([]);
  const [axis, setaxis] = useState(0);
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const fcmToken = sessionStorage.getItem("fcmToken");
  const getUserImagesData = async (userId) => {
    console.log(SelectUserData, ".....");
    try {
      const response = await axios.get(
        `${config.baseURL}getImages/${SelectUserData?.user_id}/${SelectUserData?.gender}`
      );
      // console.log(response, "................");
      if (response?.data?.recordset) {
        // console.log("....");
        const data = response?.data?.recordset;
        console.log(data, ".data...");
        setUserImagesData(data);
        // setloading(false);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };

  const getUseronlineCollageData = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.user_service_id}/${LoginUser.USER_ID}/${1}`
      );
      console.log(response, "get collage data");
      if (response?.data?.recordset) {
        console.log(
          response.data.recordset,
          "get collage dataonli",
          response.data.recordset.length
        );
        setcollageList(response.data.recordset);

        if (response.data.recordset.length !== 0) {
          console.log("axis....", response.data.recordset.length);
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };
  const getUserCollageData = async (userId) => {
    console.log(SelectUserData, ".....");
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.user_service_id}/${LoginUser.USER_ID}/${0}`
      );
      // console.log(response)
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");

        setcollageList(response.data.recordset);
        setloading(false);
        if (response.data.recordset.length === 0) {
          console.log("0....");

          await getUseronlineCollageData();
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };

  useEffect(() => {
    getUserCollageData();
    getUserImagesData();
    // handleTemplateSelection(5)
  }, [SelectUserData]);
  const CollageNotify = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const handleDelete = async (record) => {
    console.log(record, "kisikjdnjk");
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        await getUserCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const updateCollage = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),

        COLLAGE: "Stylist completed your outfit",
        SERVICE_ID: SelectUserData.SERVICE_ID.toString(),
        CUSTOMER_ID: SelectUserData.user_id.toString(),
        STYLIST_TOKEN: fcmToken,
        CUSTOMER_TOKEN: SelectUserData.fcm_token,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        await CollageNotify();
        await getUserCollageData();
        await getUserImagesData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: LoginUser.USER_ID,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const UploadSingleColllage = async (path) => {
    try {
      const formData = new FormData();
      if (path) {
        formData.append("picture", path);
      }

      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),

          STYLIST_ID: LoginUser.USER_ID,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_LIST: uploadDragImageList,
          LINK_LIST: uploadDragImageList.toString(),
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_ID,
          AXIS: 0,
          SCRAPE_DATA: "",
          COLLAGE_DESCRIPTION:''
        })
      );

      const response = await axios.post(
        `${config.baseURL}createdCollageList`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        console.log(response.data);
        message.success(" Collage Created successfully");
        await collageImagesList();
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };

  const uploadCreatedCollage = async (file) => {
    console.log(file, ";;;;;.......................");
    await UploadSingleColllage(file);
  };
  const handleTemplateSelection = (template) => {
    setTemplateSelection(template);
  };
  return (
    <div>
      {loading ? (
        <div className="loading">
          <Spin tip="Loading..." style={{ marginTop: "5%" }} />
        </div>
      ) : (
        <div>
          {/* <h1>hello </h1> */}
          <div className="userProfileMain">
            {/* <h1 className="heading"> View Collage</h1> */}
          </div>
          {axis === 1 && (
            <div>
              <div>
                <h1 className="heading"> View Collage</h1>
              </div>
              <div className="onlineProduct onlineProductWardrobe">
                {collageList.map((item, index) => (
                  <CollageComponent
                    key={index}
                    count={index + 1}
                    imageUrl={`${item?.IMAGE_NAME}`}
                    Delete={() => {
                      setloading(true);
                      setaxis(0);
                      handleDelete(item);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {axis === 0 && (
            <>
              <h1 className="heading"> Create Collage</h1>
              <div>
                {/* {UserImagesData.map((item, index) => (
              <div>
                <h2>{item.WEAR_TYPE}</h2>
                <h3>{item.IMAGE_CATEGORY}</h3>
                <img src={`${config.imageUrl}/${item.IMAGE_NAME}`} alt="" />
              </div>
            ))} */}
              </div>
              <div className="Shopping-Collage-Header">
                <div className="Wardrobesubtabs">
                  <h2>Wardrobe</h2>
                </div>

                <div className="Wardrobesubtabs">
                  <p>Select a template</p>{" "}
                  <Flex
                    horizontal
                    className="Shopping-Collage-Header"
                    style={{ margin: "2% 0 0 0" }}
                  >
                    {/* <div>
                  <p>Select a template</p>
                </div> */}
                    <div className="Template-Options waddropselectfarme">
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(5)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreFilled />
                        </div>
                        <p>5 Images</p>
                      </Flex>
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(6)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreOutlined />
                        </div>
                        <p>6 Images</p>
                      </Flex>
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(7)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreOutlined />
                        </div>
                        <p>7 Images</p>
                      </Flex>
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(8)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreOutlined />
                        </div>
                        <p>8 Images</p>
                      </Flex>
                    </div>
                  </Flex>
                </div>
              </div>
              {TemplateSelection > 0 && (
                <div style={{ margin: "2% 0" }}>
                  {UserImagesData && (
                    <ImageUploadAndPreview
                      key={`template-${TemplateSelection}`}
                      UserWardrobeImagesData={UserImagesData}
                      defaultFrameSize={
                        TemplateSelection ? TemplateSelection : 5
                      }
                      SelectUserData={SelectUserData}
                      uploadCreatedCollage={uploadCreatedCollage}
                      uploadDragImageList={(data) =>
                        setuploadDragImageList(data)
                      }
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default WardrobeCollage;
