// // import React, { useState, useRef, useEffect } from "react";
// // import { Card, Row, Col, Button, message } from "antd";
// // import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
// // import html2canvas from "html2canvas";

// // const ImageUploadAndPreview = ({ sampleImages, defaultFrameSize }) => {
// //   const [fileList, setFileList] = useState([]);
// //   const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
// //   const [images, setImages] = useState([]);
// //   const elementRef = useRef();

// //   useEffect(() => {
// //     setFileList(new Array(frameSize).fill(null));
// //   }, [frameSize]);

// //   const handleDrop = (index, src) => {
// //     const newFileList = [...fileList];
// //     newFileList[index] = src;
// //     setFileList(newFileList);
// //   };

// //   const getImagePositions = (size) => {
// //     const positions = {
// //       5: [
// //         { x: 0, y: 0, width: 200, height: 600 },
// //         { x: 200, y: 0, width: 200, height: 600 },
// //         { x: 400, y: 0, width: 200, height: 200 },
// //         { x: 400, y: 200, width: 200, height: 200 },
// //         { x: 400, y: 400, width: 200, height: 200 },
// //       ],
// //       6: [
// //         { x: 0, y: 0, width: 200, height: 300 },
// //         { x: 200, y: 0, width: 200, height: 300 },
// //         { x: 400, y: 0, width: 200, height: 200 },
// //         { x: 400, y: 200, width: 200, height: 200 },
// //         { x: 400, y: 400, width: 200, height: 200 },
// //         { x: 200, y: 300, width: 200, height: 200 },
// //       ],
// //       7: [
// //         { x: 0, y: 0, width: 200, height: 200 },
// //         { x: 200, y: 0, width: 200, height: 200 },
// //         { x: 400, y: 0, width: 200, height: 200 },
// //         { x: 0, y: 200, width: 200, height: 200 },
// //         { x: 200, y: 200, width: 200, height: 200 },
// //         { x: 400, y: 200, width: 200, height: 200 },
// //         { x: 0, y: 400, width: 200, height: 200 },
// //       ],
// //     };
// //     return positions[size] || [];
// //   };

// //   const handleConvert = () => {
// //     const imageUrls = fileList.filter((src) => src !== null);
// //     if (imageUrls.length === 0) {
// //       message.error("Please add images to the placeholders.");
// //       return;
// //     }

// //     const loadedImages = imageUrls.map((src, index) => ({
// //       src,
// //       ...getImagePositions(frameSize)[index],
// //     }));

// //     setImages(loadedImages);
// //     message.success("Images combined successfully!");
// //   };

// //   const handleUpload = () => {
// //     if (!elementRef.current) return;

// //     html2canvas(elementRef.current).then((canvas) => {
// //       const imgData = canvas.toDataURL("image/png");
// //       const link = document.createElement("a");
// //       link.href = imgData;
// //       link.download = "screenshot.png";
// //       link.click();
// //     });
// //   };

// //   return (
// //     <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
// //       {/* Upper section: Image preview and drag-and-drop areas */}
// //       <div style={{ display: "flex", gap: "20px" }}>
// //         {/* Left side: Sample images */}
// //         <div style={{ flex: 1 }}>
// //           <Row gutter={[16, 16]}>
// //             {sampleImages.map((src, index) => (
// //               <Col span={6} key={index}>
// //                 <img
// //                   src={src}
// //                   alt={`Sample ${index + 1}`}
// //                   style={{ width: "100%", height: "auto", cursor: "grab" }}
// //                   draggable="true"
// //                   onDragStart={(e) =>
// //                     e.dataTransfer.setData("text/plain", src)
// //                   }
// //                 />
// //               </Col>
// //             ))}
// //           </Row>
// //         </div>

// //         {/* Right side: Card with drag-and-drop placeholders */}
// //         <div style={{ flex: 1 }}>
// //           <Card
// //             title={
// //               <div>
// //                 <PictureOutlined style={{ marginRight: "10px" }} />
// //                 Drag and Drop Images Here (Collage Format)
// //               </div>
// //             }
// //             style={{ marginBottom: "20px" }}
// //           >
// //             <div
// //               style={{
// //                 display: "grid",
// //                 gridTemplateColumns: "repeat(3, 1fr)",
// //                 gridTemplateRows: frameSize === 5 ? "1fr" : "repeat(2, 1fr)",
// //                 gap: "10px",
// //               }}
// //             >
// //               {Array.from({ length: frameSize }, (_, index) => (
// //                 <div
// //                   key={index}
// //                   className="drop-area"
// //                   onDragOver={(e) => e.preventDefault()}
// //                   onDrop={(e) => {
// //                     e.preventDefault();
// //                     const src = e.dataTransfer.getData("text/plain");
// //                     handleDrop(index, src);
// //                   }}
// //                   style={{
// //                     border: "2px dashed #d9d9d9",
// //                     borderRadius: "8px",
// //                     padding: "10px",
// //                     height: "150px",
// //                     display: "flex",
// //                     alignItems: "center",
// //                     justifyContent: "center",
// //                     background: fileList[index] ? "none" : "#fafafa",
// //                   }}
// //                 >
// //                   {fileList[index] ? (
// //                     <img
// //                       src={fileList[index]}
// //                       alt={`Placeholder ${index + 1}`}
// //                       style={{ maxWidth: "100%", maxHeight: "100%" }}
// //                     />
// //                   ) : (
// //                     <InboxOutlined
// //                       style={{ fontSize: "24px", color: "#999" }}
// //                     />
// //                   )}
// //                 </div>
// //               ))}
// //             </div>
// //           </Card>

// //           {/* Buttons for handling operations */}
// //           <Button
// //             type="primary"
// //             onClick={handleConvert}
// //             style={{ width: "100%", marginBottom: "10px" }}
// //           >
// //             Convert to Collage
// //           </Button>
// //           <Button type="default" onClick={handleUpload} style={{ width: "100%" }}>
// //             Upload Collage
// //           </Button>
// //         </div>
// //       </div>

// //       {/* Lower section: Preview collage */}
// //       <div ref={elementRef} style={{ position: "relative", width: "600px", height: "600px", margin: "0 auto", background: "#fff", border: "1px solid #ccc" }}>
// //         {images.map((img, index) => (
// //           <img
// //             key={index}
// //             src={img.src}
// //             alt={`Collage ${index + 1}`}
// //             style={{
// //               position: "absolute",
// //               left: `${img.x}px`,
// //               top: `${img.y}px`,
// //               width: `${img.width}px`,
// //               height: `${img.height}px`,
// //             }}
// //           />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default ImageUploadAndPreview;

// import React, { useState, useRef, useEffect } from "react";
// import { Card, Row, Col, Button, message } from "antd";
// import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
// import html2canvas from "html2canvas";

// const ImageUploadAndPreview = ({ sampleImages, defaultFrameSize }) => {
//   const [fileList, setFileList] = useState([]);
//   const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
//   const [images, setImages] = useState([]);
//   const elementRef = useRef();
//   const canvasRef = useRef(null);

//   const drawImagesOnCanvas = async () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     if (!ctx) return;

//     // Set canvas dimensions to the maximum width and height required
//     const maxWidth = Math.max(...images.map((img) => img.x + img.width));
//     const maxHeight = Math.max(...images.map((img) => img.y + img.height));
//     canvas.width = maxWidth;
//     canvas.height = maxHeight;

//     // Load all images
//     const imagePromises = images.map((image) => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.crossOrigin = "Anonymous"; // Handle CORS
//         img.src = image.src;
//         img.onload = () => {
//           ctx.drawImage(img, image.x, image.y, image.width, image.height);
//           resolve();
//         };
//         img.onerror = (error) => {
//           console.error("Error loading image:", error);
//           reject(error);
//         };
//       });
//     });

//     try {
//       await Promise.all(imagePromises);
//     } catch (error) {
//       console.error("Error drawing images on canvas:", error);
//     }
//   };

//   const handleDownloadm = async () => {
//     await drawImagesOnCanvas();

//     const canvas = canvasRef.current;
//     const link = document.createElement("a");
//     link.href = canvas.toDataURL("image/png");
//     link.download = "collage.png";
//     link.click();
//   };
//   useEffect(() => {
//     setFileList(new Array(frameSize).fill(null));
//   }, [frameSize]);

//   const handleDrop = (index, src) => {
//     const newFileList = [...fileList];
//     newFileList[index] = src;
//     setFileList(newFileList);
//   };

//   const loadImage = (src) => {
//     return new Promise((resolve, reject) => {
//       const img = new window.Image();
//       img.src = src;
//       img.onload = () => resolve(img);
//       img.onerror = reject;
//     });
//   };

//   const handleConvert = async () => {
//     const imageUrls = fileList.filter((src) => src !== null);
//     if (imageUrls.length === 0) {
//       message.error("Please add images to the placeholders.");
//       return;
//     }

//     const loadedImages = await Promise.all(imageUrls.map(loadImage));
//     const newImages = loadedImages.map((img, index) => ({
//       src: img.src,
//       x: getImagePositions(frameSize)[index].x,
//       y: getImagePositions(frameSize)[index].y,
//       width: getImagePositions(frameSize)[index].width,
//       height: getImagePositions(frameSize)[index].height,
//     }));

//     setImages(newImages);
//     message.success("Images combined successfully!");
//   };

//   const getImagePositions = (size) => {
//     if (size === 5) {
//       return [
//         { x: 0, y: 0, width: 200, height: 600 },
//         { x: 200, y: 0, width: 200, height: 600 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 400, y: 400, width: 200, height: 200 },
//       ];
//     } else if (size === 6) {
//       return [
//         { x: 0, y: 0, width: 200, height: 300 },
//         { x: 200, y: 0, width: 200, height: 300 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 400, y: 400, width: 200, height: 200 },
//         { x: 200, y: 300, width: 200, height: 200 },
//       ];
//     } else if (size === 7) {
//       return [
//         { x: 0, y: 0, width: 200, height: 200 },
//         { x: 200, y: 0, width: 200, height: 200 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 0, y: 200, width: 200, height: 200 },
//         { x: 200, y: 200, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 0, y: 400, width: 200, height: 200 },
//       ];
//     }
//   };

//   return (
//     <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
//       <div style={{ display: "flex", gap: "20px" }}>
//         <div style={{ flex: 1 }}>
//           <Row gutter={[16, 16]}>
//             {sampleImages.map((src, index) => (
//               <Col span={6} key={index}>
//                 <img
//                   src={src}
//                   alt={`Sample ${index + 1}`}
//                   style={{ width: "100%", height: "auto", cursor: "grab" }}
//                   draggable="true"
//                   onDragStart={(e) => e.dataTransfer.setData("text/plain", src)}
//                 />
//               </Col>
//             ))}
//           </Row>
//         </div>

//         <div style={{ flex: 1 }}>
//           <Card
//             title={
//               <div>
//                 <PictureOutlined style={{ marginRight: "10px" }} />
//                 Drag and Drop Images Here (Collage Format)
//               </div>
//             }
//             style={{ marginBottom: "20px" }}
//           >
//             <div
//               style={{
//                 display: "grid",
//                 gridTemplateColumns: "repeat(3, 1fr)",
//                 gridTemplateRows: frameSize === 5 ? "1fr" : "repeat(2, 1fr)",
//                 gap: "10px",
//               }}
//             >
//               {Array.from({ length: frameSize }, (_, index) => (
//                 <div
//                   key={index}
//                   className="drop-area"
//                   onDragOver={(e) => e.preventDefault()}
//                   onDrop={(e) => {
//                     e.preventDefault();
//                     const src = e.dataTransfer.getData("text/plain");
//                     handleDrop(index, src);
//                   }}
//                   style={{
//                     border: "2px dashed #d9d9d9",
//                     borderRadius: "8px",
//                     padding: "10px",
//                     height: "150px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     background: fileList[index] ? "none" : "#fafafa",
//                   }}
//                 >
//                   {fileList[index] ? (
//                     <img
//                       src={fileList[index]}
//                       alt={`Placeholder ${index + 1}`}
//                       style={{ maxWidth: "100%", maxHeight: "100%" }}
//                     />
//                   ) : (
//                     <InboxOutlined
//                       style={{ fontSize: "24px", color: "#999" }}
//                     />
//                   )}
//                 </div>
//               ))}
//             </div>
//           </Card>
//         </div>
//       </div>
//       {console.log(images)}
//       <div
//         ref={elementRef}
//         style={{
//           position: "relative",
//           width: "600px",
//           height: "600px",
//           margin: "0 auto",
//           background: "#fff",
//           border: "1px solid #ccc",
//         }}
//       >
//         {images.map((img, index) => (
//           <img
//             key={index}
//             src={img.src}
//             alt={`Collage ${index + 1}`}
//             style={{
//               position: "absolute",
//               left: `${img.x}px`,
//               top: `${img.y}px`,
//               width: `${img.width}px`,
//               height: `${img.height}px`,
//             }}
//           />
//         ))}
//       </div>
//       <div>
//         <canvas ref={canvasRef}  style={{display:"none"}}/>
//         <button
//           onClick={handleDownloadm}
//           style={{
//             marginTop: "10px",
//             backgroundColor: "#007bff",
//             color: "#fff",
//             border: "none",
//             padding: "10px 20px",
//             cursor: "pointer",
//           }}
//         >
//           Download Collage
//         </button>
//         <Button
//           type="primary"
//           onClick={handleConvert}
//           style={{ width: "100%", marginBottom: "10px" }}
//         >
//           Convert to Collage
//         </Button>
//       </div>
//     </div>
//   );
// };

// // export default ImageUploadAndPreview;
// import React, { useState, useRef, useEffect } from "react";
// import { Card, Row, Col, Button, message } from "antd";
// import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
// import html2canvas from "html2canvas";
// import "./ImageUploadAndPreview.css"; // Import the CSS file

// const ImageUploadAndPreview = ({ sampleImages, defaultFrameSize }) => {
//   const [fileList, setFileList] = useState([]);
//   const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
//   const [images, setImages] = useState([]);
//   const elementRef = useRef();
//   const canvasRef = useRef(null);

//   const drawImagesOnCanvas = async () => {
//     const canvas = canvasRef.current;
//     console.log(canvas,"ndslndslnlk")
//     const ctx = canvas.getContext("2d");

//     if (!ctx) return;

//     const maxWidth = Math.max(...images.map((img) => img.x + img.width));
//     const maxHeight = Math.max(...images.map((img) => img.y + img.height));
//     canvas.width = maxWidth;
//     canvas.height = maxHeight;

//     const imagePromises = images.map((image) => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.crossOrigin = "Anonymous";
//         img.src = image.src;
//         img.onload = () => {
//           ctx.drawImage(img, image.x, image.y, image.width, image.height);
//           resolve();
//         };
//         img.onerror = (error) => {
//           console.error("Error loading image:", error);
//           reject(error);
//         };
//       });
//     });

//     try {
//       await Promise.all(imagePromises);
//     } catch (error) {
//       console.error("Error drawing images on canvas:", error);
//     }
//   };

//   const handleDownloadm = async () => {
//     await drawImagesOnCanvas();

//     const canvas = canvasRef.current;
//     const link = document.createElement("a");
//     link.href = canvas.toDataURL("image/png");
//     link.download = "collage.png";
//     link.click();
//   };

//   useEffect(() => {
//     setFileList(new Array(frameSize).fill(null));
//   }, [frameSize]);

//   const handleDrop = (index, src) => {
//     const newFileList = [...fileList];
//     newFileList[index] = src;
//     setFileList(newFileList);
//   };

//   const loadImage = (src) => {
//     return new Promise((resolve, reject) => {
//       const img = new window.Image();
//       img.src = src;
//       img.onload = () => resolve(img);
//       img.onerror = reject;
//     });
//   };

//   const handleConvert = async () => {
//     const imageUrls = fileList.filter((src) => src !== null);
//     if (imageUrls.length === 0) {
//       message.error("Please add images to the placeholders.");
//       return;
//     }

//     const loadedImages = await Promise.all(imageUrls.map(loadImage));
//     const newImages = loadedImages.map((img, index) => ({
//       src: img.src,
//       x: getImagePositions(frameSize)[index].x,
//       y: getImagePositions(frameSize)[index].y,
//       width: getImagePositions(frameSize)[index].width,
//       height: getImagePositions(frameSize)[index].height,
//     }));

//     setImages(newImages);
//     message.success("Images combined successfully!");
//   };

//   const getImagePositions = (size) => {
//     if (size === 5) {
//       return [
//         { x: 0, y: 0, width: 200, height: 600 },
//         { x: 200, y: 0, width: 200, height: 600 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 400, y: 400, width: 200, height: 200 },
//       ];
//     } else if (size === 6) {
//       return [
//         { x: 0, y: 0, width: 200, height: 300 },
//         { x: 200, y: 0, width: 200, height: 300 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 400, y: 400, width: 200, height: 200 },
//         { x: 200, y: 300, width: 200, height: 200 },
//       ];
//     } else if (size === 7) {
//       return [
//         { x: 0, y: 0, width: 200, height: 200 },
//         { x: 200, y: 0, width: 200, height: 200 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 0, y: 200, width: 200, height: 200 },
//         { x: 200, y: 200, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 0, y: 400, width: 200, height: 200 },
//       ];
//     }
//   };

//   return (
//     <div className="container">
//       <div className="image-grid-container">
//         <div className="image-grid">
//           <Row gutter={[16, 16]}>
//             {sampleImages.map((src, index) => (
//               <Col span={6} key={index}>
//                 <img
//                   src={src}
//                   alt={`Sample ${index + 1}`}
//                   style={{ width: "100%", height: "auto", cursor: "grab" }}
//                   draggable="true"
//                   onDragStart={(e) => e.dataTransfer.setData("text/plain", src)}
//                 />
//               </Col>
//             ))}
//           </Row>
//         </div>

//         <div className="drop-area-grid">
//           <Card
//             title={
//               <div className="card-title">
//                 <PictureOutlined style={{ marginRight: "10px" }} />
//                 Drag and Drop Images Here (Collage Format)
//               </div>
//             }
//           >
//             <div
//               className="grid-container"
//               style={{
//                 gridTemplateRows:
//                   frameSize === 5 ? "1fr" : "repeat(2, 1fr)",
//               }}
//             >
//               {Array.from({ length: frameSize }, (_, index) => (
//                 <div
//                   key={index}
//                   className={`drop-area ${
//                     fileList[index] ? "filled" : ""
//                   }`}
//                   onDragOver={(e) => e.preventDefault()}
//                   onDrop={(e) => {
//                     e.preventDefault();
//                     const src = e.dataTransfer.getData("text/plain");
//                     handleDrop(index, src);
//                   }}
//                 >
//                   {fileList[index] ? (
//                     <img
//                       src={fileList[index]}
//                       alt={`Uploaded ${index + 1}`}
//                     />
//                   ) : (
//                     <InboxOutlined style={{ fontSize: "24px" }} />
//                   )}
//                 </div>
//               ))}
//             </div>
//           </Card>
//         </div>
//       </div>

//       <div className="canvas-container" ref={elementRef}>
//         <canvas ref={canvasRef} />
//       </div>

//       <div className="button-container">
//         <Button
//           className="download-button"
//           onClick={handleDownloadm}
//         >
//           Download Collage
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default ImageUploadAndPreview;

// .working code

// import React, { useState, useRef, useEffect } from "react";
// import { Card, Row, Col, Button, message } from "antd";
// import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
// import html2canvas from "html2canvas";
// import config from "../../config";
// import axios from "axios";
// import CollageShoppingComponent from "../cards/CollageShoppingComponent";
// const ImageUploadAndPreview = ({
//   sampleImages,
//   defaultFrameSize,
//   SelectUserData,
//   uploadCreatedCollage,
// }) => {
//   const [fileList, setFileList] = useState([]);
//   const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
//   const [images, setImages] = useState([]);
//   const elementRef = useRef();

//   const canvasRef = useRef(null);

//   const drawImagesOnCanvas = async () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     if (!ctx) return;

//     // Set canvas dimensions to the maximum width and height required
//     const maxWidth = Math.max(...images.map((img) => img.x + img.width));
//     const maxHeight = Math.max(...images.map((img) => img.y + img.height));
//     canvas.width = maxWidth;
//     canvas.height = maxHeight;

//     // Load all images
//     const imagePromises = images.map((image) => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.crossOrigin = "Anonymous"; // Handle CORS
//         img.src = image.src;
//         img.onload = () => {
//           ctx.drawImage(img, image.x, image.y, image.width, image.height);
//           resolve();
//         };
//         img.onerror = (error) => {
//           console.error("Error loading image:", error);
//           reject(error);
//         };
//       });
//     });

//     try {
//       await Promise.all(imagePromises);
//     } catch (error) {
//       console.error("Error drawing images on canvas:", error);
//     }
//   };
//   const getTemporaryDirectory = async () => {
//     // Simulate a temporary directory by creating a temporary URL
//     const tempDir = {
//       path: window.URL.createObjectURL(new Blob()),
//     };

//     return tempDir;
//   };
//   const uploadcollage = async () => {
//     await drawImagesOnCanvas();

//     const canvas = canvasRef.current;

//     canvas.toBlob(async (blob) => {
//       if (blob) {
//         const arrayBuffer = await blob.arrayBuffer();
//         const value = new Uint8Array(arrayBuffer);

//         // No need to write to the file system, just create a File object
//         const tempDir = await getTemporaryDirectory();
//         const format = new Intl.DateTimeFormat("en", {
//           year: "numeric",
//           month: "2-digit",
//           day: "2-digit",
//           hour: "2-digit",
//           minute: "2-digit",
//           second: "2-digit",
//         });
//         const date = format.format(new Date()).replace(/[^0-9]/g, "_");
//         const file = new File([value], `Collage_${date}.png`, {
//           type: "image/png",
//         });

//         // Upload the created collage file (adjust this function to handle the File object)
//         uploadCreatedCollage(file);

//         // Revoke the temporary URL after use
//         window.URL.revokeObjectURL(tempDir.path);
//       }
//     }, "image/png");
//   };
//   useEffect(() => {
//     setFileList(new Array(frameSize).fill(null));
//   }, [frameSize]);

//   const handleDrop = (index, src) => {
//     const newFileList = [...fileList];
//     newFileList[index] = src;
//     setFileList(newFileList);
//   };

//   const loadImage = (src) => {
//     return new Promise((resolve, reject) => {
//       const img = new window.Image();
//       img.src = src;
//       img.onload = () => resolve(img);
//       img.onerror = reject;
//     });
//   };

//   const handleConvert = async () => {
//     const imageUrls = fileList.filter((src) => src !== null);
//     if (imageUrls.length === 0) {
//       message.error("Please add images to the placeholders.");
//       return;
//     }

//     const loadedImages = await Promise.all(imageUrls.map(loadImage));
//     const newImages = loadedImages.map((img, index) => ({
//       src: img.src,
//       x: getImagePositions(frameSize)[index].x,
//       y: getImagePositions(frameSize)[index].y,
//       width: getImagePositions(frameSize)[index].width,
//       height: getImagePositions(frameSize)[index].height,
//     }));

//     await setImages(newImages);
//     message.success("Images combined successfully!");
//     await uploadcollage();
//   };

//   const getImagePositions = (size) => {
//     if (size === 5) {
//       return [
//         { x: 0, y: 0, width: 200, height: 300 },  // Image 1
//         { x: 200, y: 0, width: 200, height: 300 }, // Image 2
//         { x: 400, y: 0, width: 200, height: 100 }, // Image 3
//         { x: 400, y: 100, width: 200, height: 100 }, // Image 4
//         { x: 400, y: 200, width: 200, height: 100 }, // Image 5
//       ];
//     } else if (size === 6) {
//       return [
//         { x: 0, y: 0, width: 200, height: 100 },   // decreased height by 200
//         { x: 200, y: 0, width: 200, height: 100 }, // decreased height by 200
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 400, y: 400, width: 200, height: 200 },
//         { x: 200, y: 100, width: 200, height: 200 }, // adjusted y and decreased height
//       ];
//     } else if (size === 7) {
//       return [
//         { x: 0, y: 0, width: 200, height: 0 },     // decreased height by 200
//         { x: 200, y: 0, width: 200, height: 0 },   // decreased height by 200
//         { x: 400, y: 0, width: 200, height: 0 },   // decreased height by 200
//         { x: 0, y: 0, width: 200, height: 0 },     // decreased height by 200
//         { x: 200, y: 0, width: 200, height: 0 },   // decreased height by 200
//         { x: 400, y: 0, width: 200, height: 0 },   // decreased height by 200
//         { x: 0, y: 200, width: 200, height: 200 },
//       ];
//     }
//   };

//   return (
//     <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
//       <div style={{ display: "flex", gap: "20px" }}>
//         <div style={{ flex: 1 }}>
//           <Row gutter={[16, 16]}>
//             {/* {sampleImages.map((src, index) => (
//               <Col span={6} key={index}>
//                 <img
//                   src={src}
//                   alt={`Sample ${index + 1}`}
//                   style={{ width: "100%", height: "auto", cursor: "grab" }}
//                   draggable="true"
//                   onDragStart={(e) => e.dataTransfer.setData("text/plain", src)}
//                 />
//               </Col>
//             ))} */}
//             <div style={{ display: "flex", flexWrap: "wrap" }}>
//               {sampleImages.map((product, index) => (
//                 <div key={index} style={{ flex: "1 0 21%", margin: "10px" }}>
//                   <CollageShoppingComponent
//                     brand={product.brand}
//                     imageURL={product.imageURL}
//                     name={product.name}
//                     price={product.price}
//                     style={{ width: "100%", height: "auto", cursor: "grab" }}
//                     draggable="true"
//                     onDragStart={(e) =>
//                       e.dataTransfer.setData(
//                         "text/plain",
//                         `${config.baseURL}${product.imageURL}`
//                       )
//                     }
//                   />
//                 </div>
//               ))}
//             </div>
//           </Row>
//         </div>

//         <div style={{ flex: 1 }}>
//           <Card
//             title={
//               <div>
//                 <PictureOutlined style={{ marginRight: "10px" }} />
//                 Drag and Drop Images Here
//               </div>
//             }
//             style={{ marginBottom: "20px" }}
//           >
//             <div
//               style={{
//                 display: "grid",
//                 gridTemplateColumns: "repeat(3, 1fr)",
//                 gridTemplateRows: frameSize === 5 ? "1fr" : "repeat(2, 1fr)",
//                 gap: "10px",
//               }}
//             >
//               {Array.from({ length: frameSize }, (_, index) => (
//                 <div
//                   key={index}
//                   className="drop-area"
//                   onDragOver={(e) => e.preventDefault()}
//                   onDrop={(e) => {
//                     e.preventDefault();
//                     const src = e.dataTransfer.getData("text/plain");
//                     handleDrop(index, src);
//                   }}
//                   style={{
//                     border: "2px dashed #d9d9d9",
//                     borderRadius: "8px",
//                     padding: "10px",
//                     height: "150px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     background: fileList[index] ? "none" : "#fafafa",
//                   }}
//                 >
//                   {fileList[index] ? (
//                     <img
//                       src={fileList[index]}
//                       alt={`Placeholder ${index + 1}`}
//                       style={{ maxWidth: "100%", maxHeight: "100%" }}
//                     />
//                   ) : (
//                     <InboxOutlined
//                       style={{ fontSize: "24px", color: "#999" }}
//                     />
//                   )}
//                 </div>
//               ))}
//             </div>
//           </Card>
//         </div>
//       </div>
//       {console.log(images)}
//       <div
//         ref={elementRef}
//         // style={{
//         //   position: "relative",
//         //   width: "600px",
//         //   height: "600px",
//         //   margin: "0 auto",
//         //   background: "#fff",
//         //   border: "1px solid #ccc",
//         // }}
//         style={{
//           position: "relative",
//           width: "50vw", // Full viewport width
//           height: "calc(50vw * 9 / 16)", // Height based on 16:9 aspect ratio
//           margin: "0 auto",
//           background: "#fff",
//           borderRadius: "10px",
//         }}
//       >
//         {" "}
//         <div
//           style={{
//             position: "relative",
//             width: "calc(-40px + 58vw)",
//             height: "calc(-22.5px + 32.125vw)",
//             margin: "0px",
//             padding: "19px", // Added padding
//             background: "rgb(255, 255, 255)",
//             border: "2px solid rgb(204, 204, 204)",
//             borderRadius: "10px",
//             boxSizing: "border-box",
//             overflow: "hidden",
//             display: "flex",
//             alignContent: "center",
//             alignItems: "center",
//           }}
//         >
//           {images.map((img, index) => (
//             <img
//               key={index}
//               src={img.src}
//               alt={`Collage ${index + 1}`}
//               style={{
//                 position: "absolute",
//                 left: ` ${img.x}px`,
//                 top: `${img.y}px`,
//                 width: `${img.width}px`,
//                 height: ` ${img.height}px`,
//               }}
//             />
//           ))}
//         </div>
//       </div>
//       <div>
//         <canvas ref={canvasRef} style={{ display: "none" }} />

//         <button
//           onClick={handleConvert}
//           style={{
//             marginTop: "10px",
//             backgroundColor: "#007bff",
//             color: "#fff",
//             border: "none",
//             padding: "10px 20px",
//             cursor: "pointer",
//           }}
//         >
//           Convert to Collage
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ImageUploadAndPreview;

//working code

// import React, { useState, useRef, useEffect } from "react";
// import { Card, Row, Col, Button, message } from "antd";
// import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
// import html2canvas from "html2canvas";
// import config from "../../config";
// import axios from "axios";
// import CollageShoppingComponent from "../cards/CollageShoppingComponent";

// const ImageUploadAndPreview = ({
//   sampleImages,
//   defaultFrameSize,
//   SelectUserData,
//   uploadCreatedCollage,
// }) => {
//   const [fileList, setFileList] = useState([]);
//   const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
//   const [images, setImages] = useState([]);
//   const elementRef = useRef();
//   const canvasRef = useRef(null);

//   const drawImagesOnCanvas = async () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     if (!ctx) return;

//     const maxWidth = Math.max(...images.map((img) => img.x + img.width));
//     const maxHeight = Math.max(...images.map((img) => img.y + img.height));
//     canvas.width = maxWidth;
//     canvas.height = maxHeight;

//     const imagePromises = images.map((image) => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.crossOrigin = "Anonymous";
//         img.src = image.src;
//         img.onload = () => {
//           ctx.drawImage(img, image.x, image.y, image.width, image.height);
//           resolve();
//         };
//         img.onerror = (error) => {
//           console.error("Error loading image:", error);
//           reject(error);
//         };
//       });
//     });

//     try {
//       await Promise.all(imagePromises);
//     } catch (error) {
//       console.error("Error drawing images on canvas:", error);
//     }
//   };

//   const uploadcollage = async () => {
//     await drawImagesOnCanvas();

//     const canvas = canvasRef.current;
//     canvas.toBlob(async (blob) => {
//       if (blob) {
//         const arrayBuffer = await blob.arrayBuffer();
//         const value = new Uint8Array(arrayBuffer);

//         const format = new Intl.DateTimeFormat("en", {
//           year: "numeric",
//           month: "2-digit",
//           day: "2-digit",
//           hour: "2-digit",
//           minute: "2-digit",
//           second: "2-digit",
//         });
//         const date = format.format(new Date()).replace(/[^0-9]/g, "_");
//         const file = new File([value], `Collage_${date}.png`, {
//           type: "image/png",
//         });

//         uploadCreatedCollage(file);
//       }
//     }, "image/png");
//   };

//   useEffect(() => {
//     setFileList(new Array(frameSize).fill(null));
//   }, [frameSize]);

//   const handleDrop = (index, src) => {
//     const newFileList = [...fileList];
//     newFileList[index] = src;
//     setFileList(newFileList);
//   };

//   const loadImage = (src) => {
//     return new Promise((resolve, reject) => {
//       const img = new window.Image();
//       img.src = src;
//       img.onload = () => resolve(img);
//       img.onerror = reject;
//     });
//   };

//   const handleConvert = async () => {
//     const imageUrls = fileList.filter((src) => src !== null);
//     if (imageUrls.length === 0) {
//       message.error("Please add images to the placeholders.");
//       return;
//     }

//     const loadedImages = await Promise.all(imageUrls.map(loadImage));
//     const newImages = loadedImages.map((img, index) => ({
//       src: img.src,
//       x: getImagePositions(frameSize)[index].x,
//       y: getImagePositions(frameSize)[index].y,
//       width: getImagePositions(frameSize)[index].width,
//       height: getImagePositions(frameSize)[index].height,
//     }));

//     await setImages(newImages);
//     message.success("Images combined successfully!");
//     await uploadcollage();
//   };

//   const getImagePositions = (size) => {
//     if (size === 5) {
//       return [
//         { x: 0, y: 0, width: 200, height: 300 },
//         { x: 200, y: 0, width: 200, height: 300 },
//         { x: 400, y: 0, width: 200, height: 100 },
//         { x: 400, y: 100, width: 200, height: 100 },
//         { x: 400, y: 200, width: 200, height: 100 },
//       ];
//     } else if (size === 6) {
//       return [
//         { x: 0, y: 0, width: 200, height: 100 },
//         { x: 200, y: 0, width: 200, height: 100 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 400, y: 400, width: 200, height: 200 },
//         { x: 200, y: 100, width: 200, height: 200 },
//       ];
//     } else if (size === 7) {
//       return [
//         { x: 0, y: 0, width: 200, height: 200 },
//         { x: 200, y: 0, width: 200, height: 200 },
//         { x: 400, y: 0, width: 200, height: 200 },
//         { x: 0, y: 200, width: 200, height: 200 },
//         { x: 200, y: 200, width: 200, height: 200 },
//         { x: 400, y: 200, width: 200, height: 200 },
//         { x: 0, y: 400, width: 200, height: 200 },
//       ];
//     }
//   };

//   return (
//     <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
//       <div style={{ display: "flex", gap: "20px" }}>
//         <div style={{ flex: 1 }}>
//           <Row gutter={[16, 16]}>
//             <div style={{ display: "flex", flexWrap: "wrap" }}>
//               {sampleImages.map((product, index) => (
//                 <div key={index} style={{ flex: "1 0 21%", margin: "10px" }}>
//                   <CollageShoppingComponent
//                     brand={product.brand}
//                     imageURL={product.imageURL}
//                     name={product.name}
//                     price={product.price}
//                     style={{ width: "100%", height: "auto", cursor: "grab" }}
//                     draggable="true"
//                     onDragStart={(e) =>
//                       e.dataTransfer.setData(
//                         "text/plain",
//                         `${config.baseURL}${product.imageURL}`
//                       )
//                     }
//                   />
//                 </div>
//               ))}
//             </div>
//           </Row>
//         </div>

//         <div style={{ flex: 1 }}>
//           <Card
//             title={
//               <div>
//                 <PictureOutlined style={{ marginRight: "10px" }} />
//                 Drag and Drop Images Here
//               </div>
//             }
//             style={{ marginBottom: "20px" }}
//           >
//             <div
//               style={{
//                 display: "grid",
//                 gridTemplateColumns: "repeat(3, 1fr)",
//                 gridTemplateRows: frameSize === 5 ? "1fr" : "repeat(2, 1fr)",
//                 gap: "10px",
//               }}
//             >
//               {Array.from({ length: frameSize }, (_, index) => (
//                 <div
//                   key={index}
//                   className="drop-area"
//                   onDragOver={(e) => e.preventDefault()}
//                   onDrop={(e) => {
//                     e.preventDefault();
//                     const src = e.dataTransfer.getData("text/plain");
//                     handleDrop(index, src);
//                   }}
//                   style={{
//                     height: "150px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     background: fileList[index] ? "none" : "#fafafa",
//                     overflow: "hidden",
//                     border: "2px solid #d9d9d9", // Adjust the border color as needed
//                     padding: "13px",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   {fileList[index] ? (
//                     <img
//                       src={fileList[index]}
//                       alt={`Placeholder ${index + 1}`}
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "contain",
//                       }}
//                     />
//                   ) : (
//                     <InboxOutlined style={{ fontSize: "24px", color: "#bfbfbf" }} />
//                   )}
//                 </div>
//               ))}
//             </div>
//             <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
//               <Button type="primary" onClick={handleConvert}>
//                 Generate Collage
//               </Button>
//             </div>
//           </Card>
//         </div>
//       </div>

//       <div>
//         <canvas
//           ref={canvasRef}

//         />
//       </div>
//     </div>
//   );
// };

// export default ImageUploadAndPreview;

//
import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Button, message } from "antd";
import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import config from "../../config";
import axios from "axios";
// import { InboxOutlined } from '@ant-design/icons';
import CollageShoppingComponent from "../cards/CollageShoppingComponent";
import UserWardrobe from "../cards/UserWardrobe";
const ImageUploadAndPreview = ({
  sampleImages,
  defaultFrameSize,
  SelectUserData,
  uploadCreatedCollage,
  UserWardrobeImagesData,
  uploadDragImageList,
}) => {
  const [fileList, setFileList] = useState([]);
  const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
  const [images, setImages] = useState([]);
  const [disablebutton, setdisablebutton] = useState(false);
  const elementRef = useRef();
  const canvasRef = useRef(null);
  const [collagecreationtype, setcollagecreationtype] = useState("Generate");
  // const drawImagesOnCanvas = async () =>[] {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   if (!ctx) return;

  //   // Clear canvas before drawing
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);

  //   // Set canvas size
  //   const maxWidth = Math.max(...images.map((img) => img.x + img.width));
  //   const maxHeight = Math.max(...images.map((img) => img.y + img.height));
  //   canvas.width = maxWidth + 20; // Add extra space for border
  //   canvas.height = maxHeight + 20; // Add extra space for border

  //   // Draw images
  //   const imagePromises = images.map((image) => {
  //     return new Promise((resolve, reject) => {
  //       const img = new Image();
  //       img.crossOrigin = "Anonymous";
  //       img.src = image.src;
  //       img.onload = () => {
  //         ctx.drawImage(
  //           img,
  //           image.x + 10,
  //           image.y + 10,
  //           image.width,
  //           image.height
  //         ); // Offset images for border
  //         resolve();
  //       };
  //       img.onerror = (error) => {
  //         console.error("Error loading image:", error);
  //         reject(error);
  //       };
  //     });
  //   });

  //   try {
  //     await Promise.all(imagePromises);

  //     // Draw border with border radius
  //     const borderRadius = 18; // Radius for rounded corners
  //     ctx.lineWidth = 1; // Border thickness
  //     ctx.strokeStyle = "black";
  //     ctx.beginPath();
  //     ctx.moveTo(borderRadius, 0);
  //     ctx.lineTo(canvas.width - borderRadius, 0);
  //     ctx.arcTo(canvas.width, 0, canvas.width, borderRadius, borderRadius);
  //     ctx.lineTo(canvas.width, canvas.height - borderRadius);
  //     ctx.arcTo(
  //       canvas.width,
  //       canvas.height,
  //       canvas.width - borderRadius,
  //       canvas.height,
  //       borderRadius
  //     );
  //     ctx.lineTo(borderRadius, canvas.height);
  //     ctx.arcTo(
  //       0,
  //       canvas.height,
  //       0,
  //       canvas.height - borderRadius,
  //       borderRadius
  //     );
  //     ctx.lineTo(0, borderRadius);
  //     ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
  //     ctx.closePath();
  //     ctx.stroke();
  //   } catch (error) {
  //     console.error("Error drawing images on canvas:", error);
  //   }
  // };

  // // const uploadcollage = async () => {
  // //   console.log("data ......");
  // //   await drawImagesOnCanvas();

  // //   const canvas = canvasRef.current;
  // //   canvas.toBlob(async (blob) => {
  // //     if (blob) {
  // //       const arrayBuffer = await blob.arrayBuffer();
  // //       const value = new Uint8Array(arrayBuffer);

  // //       const format = new Intl.DateTimeFormat("en", {
  // //         year: "numeric",
  // //         month: "2-digit",
  // //         day: "2-digit",
  // //         hour: "2-digit",
  // //         minute: "2-digit",
  // //         second: "2-digit",
  // //       });
  // //       const date = format.format(new Date()).replace(/[^0-9]/g, "_");
  // //       const file = new File([value], `Collage_${date}.png`, {
  // //         type: "image/png",
  // //       });
  // //       console.log("data ....////..", file);
  // //       uploadCreatedCollage(file);
  // //       uploadDragImageList(fileList);
  // //       console.log("lkklkll......")
  // //     }
  // //   }, "image/png");
  // // };
  // const uploadcollage = async () => {
  //   console.log("data ......");

  //   // Wait for the canvas to be drawn
  //   await drawImagesOnCanvas();
  //   console.log("data ......");
  //   const canvas = canvasRef.current;

  //   // Create a promise for canvas.toBlob
  //   const blob = await new Promise((resolve) => {
  //     canvas.toBlob((blob) => resolve(blob), "image/png");
  //   });

  //   if (blob) {
  //     const arrayBuffer = await blob.arrayBuffer();
  //     const value = new Uint8Array(arrayBuffer);

  //     const format = new Intl.DateTimeFormat("en", {
  //       year: "numeric",
  //       month: "2-digit",
  //       day: "2-digit",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //     });
  //     const date = format.format(new Date()).replace(/[^0-9]/g, "_");

  //     // Create a file from the blob
  //     const file = new File([value], `Collage_${date}.png`, {
  //       type: "image/png",
  //     });

  //     console.log("data ....////..", file);

  //     // Upload the collage and image list
  //     await uploadCreatedCollage(file);
  //     await uploadDragImageList(fileList);

  //     console.log("lkklkll......");
  //   }
  // };

  const drawImagesOnCanvas = async (images) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    // Clear canvas before drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set canvas size
    const maxWidth = Math.max(...images.map((img) => img.x + img.width));
    const maxHeight = Math.max(...images.map((img) => img.y + img.height));
    canvas.width = maxWidth + 20; // Add extra space for border
    canvas.height = maxHeight + 20; // Add extra space for border

    // Draw images
    const imagePromises = images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = image.src;
        img.onload = () => {
          ctx.drawImage(
            img,
            image.x + 10,
            image.y + 10,
            image.width,
            image.height
          ); // Offset images for border
          resolve();
        };
        img.onerror = (error) => {
          console.error("Error loading image:", error);
          reject(error);
        };
      });
    });

    await Promise.all(imagePromises);

    // Draw border with border radius
    const borderRadius = 18; // Radius for rounded corners
    ctx.lineWidth = 1; // Border thickness
    ctx.strokeStyle = "black";
    ctx.beginPath();
    ctx.moveTo(borderRadius, 0);
    ctx.lineTo(canvas.width - borderRadius, 0);
    ctx.arcTo(canvas.width, 0, canvas.width, borderRadius, borderRadius);
    ctx.lineTo(canvas.width, canvas.height - borderRadius);
    ctx.arcTo(
      canvas.width,
      canvas.height,
      canvas.width - borderRadius,
      canvas.height,
      borderRadius
    );
    ctx.lineTo(borderRadius, canvas.height);
    ctx.arcTo(0, canvas.height, 0, canvas.height - borderRadius, borderRadius);
    ctx.lineTo(0, borderRadius);
    ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
    ctx.closePath();
    ctx.stroke();
  };

  const uploadcollage = async () => {
    try {
      console.log("Starting collage creation...");

      // Wait for the canvas to be drawn
      // await drawImagesOnCanvas();

      console.log("Canvas drawing complete");

      const canvas = canvasRef.current;
      console.log("Canvas drawing complete", canvas);
      // Create a promise for canvas.toBlob
      const blob = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Blob creation failed"));
          }
        }, "image/png");
      });

      if (blob) {
        const arrayBuffer = await blob.arrayBuffer();
        const value = new Uint8Array(arrayBuffer);

        const format = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        const date = format.format(new Date()).replace(/[^0-9]/g, "_");

        // Create a file from the blob
        const file = new File([value], `Collage_${date}.png`, {
          type: "image/png",
        });

        console.log("File created:", file);

        // Upload the collage and image list
        await uploadCreatedCollage(file);
        await uploadDragImageList(fileList);

        console.log("Upload complete.");
      }
    } catch (error) {
      console.error("Error during collage upload:", error);
    }
  };

  useEffect(() => {
    setFileList(new Array(frameSize).fill(null));
  }, [frameSize]);

  const handleDrop = (index, src) => {
    const newFileList = [...fileList];
    newFileList[index] = src;
    console.log(src, "data.......handle drop images .....");
    setFileList(newFileList);
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };
  const handleConvert = async () => {
    console.log("ksdhnin.....");
    setdisablebutton(true);
    const imageUrls = fileList.filter((src) => src !== null);
    if (imageUrls.length < 5) {
      message.error("Please add images to the placeholders.");
      setdisablebutton(false);
      return;
    }

    const loadedImages = await Promise.all(imageUrls.map(loadImage));
    const newImages = loadedImages.map((img, index) => ({
      src: img.src,
      x: getImagePositions(frameSize)[index].x,
      y: getImagePositions(frameSize)[index].y,
      width: getImagePositions(frameSize)[index].width,
      height: getImagePositions(frameSize)[index].height,
    }));

    setImages(newImages);
    await drawImagesOnCanvas(newImages);
    await uploadcollage(newImages);
    setcollagecreationtype("Upload");
  };

  const getImagePositions = (size) => {
    // if (size === 5) {
    //   return [
    //     { x: 0, y: 0, width: 195, height: 300 },

    //     { x: 200, y: 0, width: 195, height: 300 },

    //     { x: 400, y: 0, width: 195, height: 95 },
    //     { x: 400, y: 100, width: 195, height: 95 },

    //     { x: 400, y: 200, width: 195, height: 95 },
    //   ];
    // } else if (size === 6) {
    //   return [
    //     { x: 0, y: 0, width: 180, height: 300 },

    //     { x: 185, y: 0, width: 185, height: 145 },
    //     { x: 185, y: 150, width: 185, height: 145 },

    //     { x: 375, y: 0, width: 145, height: 95 },
    //     { x: 375, y: 100, width: 145, height: 95 },
    //     { x: 375, y: 200, width: 145, height: 95 },
    //   ];
    // } else if (size === 7) {
    //   return [
    //     { x: 0, y: 0, width: 180, height: 145 },
    //     { x: 0, y: 150, width: 180, height: 145 },

    //     { x: 185, y: 0, width: 185, height: 145 },
    //     { x: 185, y: 150, width: 185, height: 145 },

    //     { x: 375, y: 0, width: 145, height: 95 },
    //     { x: 375, y: 100, width: 145, height: 95 },
    //     { x: 375, y: 200, width: 145, height: 95 },
    //   ];
    // } else if (size === 8) {
    //   return [
    //     { x: 0, y: 0, width: 180, height: 145 },
    //     { x: 0, y: 150, width: 180, height: 145 },

    //     { x: 185, y: 0, width: 185, height: 95 },
    //     { x: 185, y: 100, width: 185, height: 95 },
    //     { x: 185, y: 200, width: 185, height: 95 },

    //     { x: 375, y: 0, width: 145, height: 95 },
    //     { x: 375, y: 100, width: 145, height: 95 },
    //     { x: 375, y: 200, width: 145, height: 95 },
    //   ];
    // }

    // if (size === 5) {
    //   return [
    //     { x: 0, y: 0, width: 195, height: 300 },

    //     { x: 200, y: 0, width: 195, height: 300 },

    //     { x: 400, y: 0, width: 195, height: 95 },
    //     { x: 400, y: 100, width: 195, height: 95 },

    //     { x: 400, y: 200, width: 195, height: 95 },
    //   ];
    // } else if (size === 6) {
    //   return [
    //     { x: 0, y: 0, width: 195, height: 300 },

    //     { x: 200, y: 0, width: 195, height: 145 },
    //     { x: 200, y: 150, width: 195, height: 145 },

    //     { x: 400, y: 0, width: 195, height: 95 },
    //     { x: 400, y: 100, width: 195, height: 95 },
    //     { x: 400, y: 200, width: 195, height: 95 },
    //   ];
    // } else if (size === 7) {
    //   return [
    //     { x: 0, y: 0, width: 195, height: 145 },
    //     { x: 0, y: 150, width: 195, height: 145 },

    //     { x: 200, y: 0, width: 195, height: 145 },
    //     { x: 200, y: 150, width: 195, height: 145 },

    //     { x: 400, y: 0, width: 195, height: 95 },
    //     { x: 400, y: 100, width: 195, height: 95 },
    //     { x: 400, y: 200, width: 195, height: 95 },
    //   ];
    // } else if (size === 8) {
    //   return [
    //     { x: 0, y: 0, width: 195, height: 145 },
    //     { x: 0, y: 150, width: 195, height: 145 },

    //     { x: 200, y: 0, width: 195, height: 95 },
    //     { x: 200, y: 100, width: 195, height: 95 },
    //     { x: 200, y: 200, width: 195, height: 95 },

    //     { x: 400, y: 0, width: 195, height: 95 },
    //     { x: 400, y: 100, width: 195, height: 95 },
    //     { x: 400, y: 200, width: 195, height: 95 },
    //   ];
    // }

    if (size === 5) {
      return [
        { x: 0, y: 0, width: 195, height: 300 },

        { x: 200, y: 0, width: 195, height: 300 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },

        { x: 400, y: 200, width: 147, height: 95 },
      ];
    } else if (size === 6) {
      return [
        { x: 0, y: 0, width: 195, height: 300 },

        { x: 200, y: 0, width: 195, height: 145 },
        { x: 200, y: 150, width: 195, height: 145 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 95 },
      ];
    } else if (size === 7) {
      return [
        { x: 0, y: 0, width: 195, height: 145 },
        { x: 0, y: 150, width: 195, height: 145 },

        { x: 200, y: 0, width: 195, height: 145 },
        { x: 200, y: 150, width: 195, height: 145 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 95 },
      ];
    } else if (size === 8) {
      return [
        { x: 0, y: 0, width: 195, height: 145 },
        { x: 0, y: 150, width: 195, height: 145 },

        { x: 200, y: 0, width: 195, height: 95 },
        { x: 200, y: 100, width: 195, height: 95 },
        { x: 200, y: 200, width: 195, height: 95 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 95 },
      ];
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", gap: "20px", height: "55.5vh" }}>
        {/* <div style={{ flex: 1 }}> */}
        <div style={{ flex: 1, overflow: "scroll", overflowX: "hidden" }}>
          {sampleImages ? (
            // <div style={{ flex: 1, overflow: "scroll", overflowX: "hidden" }}>
              <Row gutter={[16, 16]}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {sampleImages.map((product, index) => (
                    <div
                      key={index}
                      style={{ flex: "1 0 21%", margin: "10px" }}
                    >
                      <CollageShoppingComponent
                        brand={product.brand}
                        imageURL={product.imageURL}
                        name={product.name}
                        price={product.price}
                        style={{
                          width: "100%",
                          height: "auto",
                          cursor: "grab",
                        }}
                        draggable="true"
                        onDragStart={(e) =>
                          e.dataTransfer.setData(
                            "text/plain",
                            `${config.baseURL}${product.imageURL}`
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </Row>
            // </div>
          ) : (
            <Row gutter={[16, 16]}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {/* {UserWardrobeImagesData.map((item, index) => (
                  <div key={index} style={{ flex: "1 0 21%", margin: "10px" }}>
                    <div>
                      <h2>{item.WEAR_TYPE}</h2>
                      <h3>{item.IMAGE_CATEGORY}</h3>
                      <img
                        src={`${config.imageUrl}/${item.IMAGE_NAME}`}
                        alt=""
                      />
                    </div>
                  </div>
                ))} */}
                <UserWardrobe UserWardrobeImagesData={UserWardrobeImagesData} />
              </div>
            </Row>
          )}
        </div>

        <div
          style={{ flex: 1, border: "2px solid #d9d9d9", borderRadius: "8px" }}
        >
          {/* <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <PictureOutlined style={{ marginRight: "10px" }} />
                Drag and Drop Images Here
                <Button className="button" onClick={() => handleConvert()}>
                  Generate Collage
                </Button>
              </div>
            }
            style={{ marginBottom: "20px" }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridTemplateRows: frameSize === 5 ? "1fr" : "repeat(2, 1fr)",
                gap: "10px",
              }}
            >
              {Array.from({ length: frameSize }, (_, index) => (
                <div
                  key={index}
                  className="drop-area"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const src = e.dataTransfer.getData("text/plain");
                    handleDrop(index, src);
                  }}
                  style={{
                    // height: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: fileList[index] ? "none" : "#fafafa",
                    overflow: "hidden",
                    border: "2px solid #d9d9d9",
                    padding: "13px",
                    borderRadius: "4px",
                  }}
                >
                  {fileList[index] ? (
                    <img
                      src={fileList[index]}
                      alt={`placeholder-${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <InboxOutlined
                      style={{ fontSize: "24px", color: "#bfbfbf" }}
                    />
                  )}
                </div>
              ))}
            </div>
          </Card> */}
          {/* <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <PictureOutlined style={{ marginRight: "10px" }} />
                Drag and Drop Images Here
                <Button className="button" onClick={() => handleConvert()}>
                  Generate Collage
                </Button>
              </div>
            }
            style={{
              marginBottom: "20px",
              position: "relative",
              height: "300px",
            }} // Adjust height based on the total collage height
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              {getImagePositions(frameSize).map((pos, index) => (
                <div
                  key={index}
                  className="drop-area"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const src = e.dataTransfer.getData("text/plain");
                    handleDrop(index, src);
                  }}
                  style={{
                    position: "absolute",
                    top: pos.y/1.5,
                    left: pos.x/1.5,
                    width: pos.width/1.5,
                    height: pos.height/1.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: fileList[index] ? "none" : "#fafafa",
                    overflow: "hidden",
                    border: "2px solid #d9d9d9",
                    padding: "13px",
                    borderRadius: "4px",
                    
                  }}
id="zoom-effect"
                >
                  {fileList[index] ? (
                    <img  
                      src={fileList[index]}
                      alt={`placeholder-${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        // objectFit: "cover",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <InboxOutlined
                      style={{ fontSize: "24px", color: "#bfbfbf" }}
                    />
                  )}
                </div>
              ))}
            </div>
          </Card> */}
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <PictureOutlined style={{ marginRight: "10px" }} />
                Drag and Drop Images Here
                <Button
                  className="button"
                  disabled={disablebutton}
                  onClick={() => handleConvert()}
                >
                  {collagecreationtype} Collage
                </Button>
              </div>
            }
            style={{
              marginBottom: "20px",
              position: "relative",
              // height: "300px",
            }} // Adjust height based on the total collage height
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              {getImagePositions(frameSize).map((pos, index) => (
                <div
                  key={index}
                  // className="drop-area"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const src = e.dataTransfer.getData("text/plain");
                    handleDrop(index, src);
                  }}
                  style={{
                    position: "absolute",
                    top: pos.y / 1.5,
                    left: pos.x / 1.5,
                    width: pos.width / 1.5,
                    height: pos.height / 1.5,
                    // top: pos.y ,
                    // left: pos.x ,
                    // width: pos.width ,
                    // height: pos.height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: fileList[index] ? "none" : "#fafafa",
                    overflow: "hidden",
                    border: "2px solid #d9d9d9",
                    padding: "7px",
                    borderRadius: "4px",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  className="drop-area zoom-effect"
                >
                  {fileList[index] ? (
                    <img
                      src={fileList[index]}
                      alt={`placeholder-${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <InboxOutlined
                      style={{ fontSize: "24px", color: "#bfbfbf" }}
                    />
                  )}
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>

      <div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default ImageUploadAndPreview;
