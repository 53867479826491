// import React from 'react';
// import { Form, Input, Button, DatePicker, Row, Col, Flex } from 'antd';
// import axios from 'axios';
// import './Profile.css'
// import Profile from "../../images/profile.jpg";
// import config from '../../config';

// const ProfileForm = () => {
//   const [form] = Form.useForm();
//   const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
//   console.log(LoginUser,'LoginuserTest........')

//   const onFinish = async (values) => {
//     try {
//       const response = await axios.post('https://your-api-endpoint.com/profile', values);
//       console.log('Profile saved successfully:', response.data);
//     } catch (error) {
//       console.error('Error saving profile:', error);
//     }
//   };

//   const onCancel = () => {
//     form.resetFields();
//   };

//   return (
//     <Row align="middle" className='Profile-Content'>
//       <Col span={22} xs={24} sm={20} md={16} lg={14}>
//         <Flex align='center' className='Profile-Header-Logo'>
//           <div style={{ marginBottom: 20 }}>
//           <img
//                 src={`${LoginUser?.USER_PHOTO ? `${config.imageUrl}${LoginUser?.USER_PHOTO}` : Profile}`}
//                 alt=""
//                 width={"130px"}
//                 height={"130px"}
//                 style={{ marginRight: 8 ,borderRadius: "50%"}}
//               />
//           </div>
//           <Form.Item className='Profile-Header-Buttons'>
//             <Flex>
//               <Button className='' type="primary" htmlType="submit" style={{ marginRight: 10, }}>
//                 Edit
//               </Button>
//               <Button onClick={onCancel} type="primary" style={{}}>
//                 Submit
//               </Button>
//             </Flex>
//           </Form.Item>
//         </Flex>
//         <Form form={form} name="profile_form" onFinish={onFinish} layout="vertical">
//           <Flex gap={20}>
//             <Col span={14}>
//               <Form.Item
//                 className='Profile-FormItem'
//                 name="firstname"
//                 label="First Name"
//                 rules={[{ required: true, message: 'Please input your first name!' }]}
//               >
//                 <Input className='Profile-Form-Input' placeholder="Enter your first name" />
//               </Form.Item>
//             </Col>
//             <Col span={14}>
//               <Form.Item
//                 className='Profile-FormItem'
//                 name="lastname"
//                 label="Last Name"
//                 rules={[{ required: true, message: 'Please input your last name!' }]}
//               >
//                 <Input className='Profile-Form-Input' placeholder="Enter your last name" />
//               </Form.Item>
//             </Col>
//           </Flex>
//           <Flex gap={20}>
//             <Col span={14}>
//               <Form.Item
//                 className='Profile-FormItem'
//                 name="dateofbirth"
//                 label="Date of Birth"
//                 rules={[{ required: true, message: 'Please select your date of birth!' }]}
//               >
//                 <DatePicker className='Profile-Form-Input' style={{ width: '100%' }} />
//               </Form.Item>
//             </Col>
//             <Col span={14}>
//               <Form.Item
//                 className='Profile-FormItem'
//                 name="weight"
//                 label="Weight (kg)"
//                 rules={[{ required: true, message: 'Please input your weight!' }]}
//               >
//                 <Input className='Profile-Form-Input' type="number" placeholder="Enter your weight" />
//               </Form.Item>
//             </Col>
//           </Flex>
//           <Flex gutter={16}>
//             <Col span={14}>
//               <Form.Item
//                 className='Profile-FormItem'
//                 name="height"
//                 label="Height (cm)"
//                 rules={[{ required: true, message: 'Please input your height!' }]}
//               >
//                 <Input className='Profile-Form-Input' type="number" placeholder="Enter your height" />
//               </Form.Item>
//             </Col>
//           </Flex>

//         </Form>
//       </Col>
//     </Row>
//   );
// };

// export default ProfileForm;

import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  Upload,
  Radio,
  Flex,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import "./Profile.css";
import Profileimage from "../../images/profile.jpg";
import { RcFile } from "antd/es/upload";
// import { UploadOutlined } from '@ant-design/icons';
// import {  } from 'antd';

const ProfileForm = () => {
  const [form] = Form.useForm();
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstname: LoginUser.FIRST_NAME || "",
    lastname: LoginUser.LAST_NAME || "",
    dateofbirth: LoginUser?.AGE ? moment(getDateOfBirth(LoginUser?.AGE)) : null,
    weight: LoginUser.WEIGHT || "",
    height: LoginUser.HEIGHT || "",
    gender: LoginUser.GENDER || "Male", // Set default gender
    userPhoto: [],
  });

  const [image, setImage] = useState(
    LoginUser?.USER_PHOTO
      ? `${config.imageUrl}${LoginUser?.USER_PHOTO}`
      : Profileimage
  );
  const [imageFile, setImageFile] = useState(null);

  // Handle image file selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setImageFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle image upload (assuming you have an upload function)
  const handleImageUpload = async () => {
    if (!imageFile) {
      message.warning("No file selected.");
      return;
    }

    // Mock upload function (you can replace this with your actual upload logic)
    try {
      // const response = await uploadImage(imageFile);
      message.success("Image uploaded successfully!");
      // Update LoginUser or state as necessary after upload
    } catch (error) {
      message.error("Image upload failed.");
    }
  };

  // Trigger file input click
  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  // console.log(LoginUser?.AGE,'agecheck......',getDateOfBirth(LoginUser?.AGE))
  function getDateOfBirth(age) {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    const dob = new Date(today.setFullYear(birthYear));

    // Adjust the month and day to the current month and day to match the input age
    dob.setMonth(today.getMonth());
    dob.setDate(today.getDate());

    return dob.toISOString().split("T")[0]; // Format YYYY-MM-DD
  }

  const calculateAge = (dobString) => {
    const dob = moment(dobString, "DD/MM/YYYY");
    const age = moment().diff(dob, "years");

    return age;
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      if (image) {
        //  const blob = new Blob([image], { type: image.type });
        //  console.log(image,"sldjsladjlill ",blob)
        formData.append("picture", imageFile);
      }
      formData.append(
        "data",
        JSON.stringify({
          FIRST_NAME: values.firstname,
          LAST_NAME: values.lastname,
          STYLIST_ID: LoginUser.USER_ID,
          AGE: values.dateofbirth
            ? calculateAge(values.dateofbirth).toString()
            : "",
          WEIGHT: values.weight,
          HEIGHT: values.height,
          GENDER: values.gender,
          USER_ID: LoginUser.USER_ID,
        })
      );

      console.log(formData, "post data ", values);
      const response = await axios.post(
        `${config.baseURL}editStylistProfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.message === "success") {
        console.log(response, "Profile saved successfully:", response.data);
        sessionStorage.setItem(
          "LoginUser",
          JSON.stringify(response?.data?.userdata?.[0])
        );
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadContainerRef = useRef(null);

  const handleChange = (info) => {
    if (info.file.status === "done") {
      // Update the profile picture with the uploaded file URL or data
      console.log("File uploaded:", info.file);
    }
  };

  const userId = LoginUser.USER_ID;

  return (
    // <Row align="middle" className="Profile-Content">
    //   <Col span={22}>
    //     <Flex align="flex-end" className="Profile-Header-Logo">
    //       <Flex align="center" gap={30}>
    //         <div>
    //           {/* {console.log(image, "image.....")} */}
    //           <img
    //             src={image}
    //             // src={`${LoginUser?.USER_PHOTO ? `${config.imageUrl}${LoginUser?.USER_PHOTO}` : Profileimage}`}
    //             alt="Profile"
    //             width="130px"
    //             height="130px"
    //             style={{ borderRadius: "50%", cursor:isEditing? "pointer" :'none'}}
    //             onClick={handleImageClick}
    //           />
    //           <input
    //             id="file-input"
    //             type="file"
    //             style={{ display: "none" }}
    //             accept="image/*"
    //             onChange={handleImageChange}
    //           />
    //           {/* <Button
    //             icon={<UploadOutlined />}
    //             onClick={handleImageUpload}
    //             style={{ marginTop: '10px' }}
    //           >
    //             Upload
    //           </Button> */}
    //         </div>
    //         <div ref={uploadContainerRef}>
    //           <Upload
    //             name="userPhoto"
    //             listType="picture"
    //             maxCount={1}
    //             beforeUpload={() => false} // Prevents auto upload
    //             onChange={handleChange}
    //             showUploadList={false} // Hide default upload list
    //           >
    //             <Button icon={<UploadOutlined />} style={{ display: "none" }}>
    //               Click to upload
    //             </Button>
    //           </Upload>
    //         </div>
    //       </Flex>
    //       <Form.Item className="Profile-Header-Buttons">
    //         {console.log(LoginUser, "......")}
    //         {LoginUser.ROLE === "0" && !isEditing ? (
    //           <Flex>
    //             <Button onClick={onEdit}>Edit</Button>
    //           </Flex>
    //         ) : isEditing ? (
    //           <Flex>
    //             <Col span={12}>
    //               <Form.Item
    //                 name="userPhoto"
    //                 valuePropName="fileList"
    //                 getValueFromEvent={normFile}
    //               />
    //             </Col>
    //             <Flex gap={20}>
    //               <Button htmlType="submit" form="profile_form">
    //                 Submit
    //               </Button>
    //               <Button onClick={onCancel} type="default">
    //                 Cancel
    //               </Button>
    //             </Flex>
    //           </Flex>
    //         ) : null}
    //       </Form.Item>
    //     </Flex>
    //     <Form
    //       form={form}
    //       name="profile_form"
    //       onFinish={onFinish}
    //       layout="vertical"
    //       initialValues={initialValues}
    //       disabled={!isEditing}
    //       encType="multipart/form-data"
    //     >
    //       <Row gutter={20}>
    //         <Col span={12}>
    //           <Form.Item
    //             className="Profile-FormItem"
    //             name="firstname"
    //             label="First Name"
    //             rules={[
    //               { required: true, message: "Please input your first name!" },
    //             ]}
    //           >
    //             <Input
    //               className="Profile-Form-Input"
    //               placeholder="Enter your first name"
    //             />
    //           </Form.Item>
    //         </Col>
    //         <Col span={12}>
    //           <Form.Item
    //             className="Profile-FormItem"
    //             name="lastname"
    //             label="Last Name"
    //             rules={[
    //               { required: true, message: "Please input your last name!" },
    //             ]}
    //           >
    //             <Input
    //               className="Profile-Form-Input"
    //               placeholder="Enter your last name"
    //             />
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //       <Row gutter={20}>
    //         <Col span={12}>
    //           <Form.Item
    //             className="Profile-FormItem"
    //             name="dateofbirth"
    //             label="Date of Birth"
    //             rules={[
    //               {
    //                 required: true,
    //                 message: "Please select your date of birth!",
    //               },
    //             ]}
    //           >
    //             <DatePicker
    //               className="Profile-Form-Input"
    //               style={{ width: "100%" }}
    //             />
    //           </Form.Item>
    //         </Col>
    //         <Col span={12}>
    //           <Form.Item
    //             className="Profile-FormItem"
    //             name="weight"
    //             label="Weight (kg)"
    //             rules={[
    //               { required: true, message: "Please input your weight!" },
    //             ]}
    //           >
    //             <Input
    //               className="Profile-Form-Input"
    //               type="number"
    //               placeholder="Enter your weight"
    //             />
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //       <Row gutter={20}>
    //         <Col span={12}>
    //           <Form.Item
    //             className="Profile-FormItem"
    //             name="height"
    //             label="Height (cm)"
    //             rules={[
    //               { required: true, message: "Please input your height!" },
    //             ]}
    //           >
    //             <Input
    //               className="Profile-Form-Input"
    //               type="number"
    //               placeholder="Enter your height"
    //             />
    //           </Form.Item>
    //         </Col>
    //         <Col span={12}>
    //           <Form.Item
    //             className="Profile-FormItem"
    //             name="gender"
    //             label="Gender"
    //             rules={[
    //               { required: true, message: "Please select your gender!" },
    //             ]}
    //           >
    //             <Radio.Group>
    //               <Radio value="Male">Male</Radio>
    //               <Radio value="Female">Female</Radio>
    //             </Radio.Group>
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //     </Form>
    //   </Col>
    // </Row>
    <Row align="middle" className="Profile-Content">
    <Col span={22}>
      <Flex align="flex-end" className="Profile-Header-Logo">
        <Flex align="center" gap={30}>
          {/* <div style={{display:'flex',alignItems:'center' , }}> */}
          <div>
            <img
              src={image}
              alt="Profile"
              width="130px"
              height="130px"
              style={{ borderRadius: "50%", cursor: "pointer" }}
              onClick={handleImageClick}
            />
            <input
              id="file-input"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div className="Profile-Header-Buttons">
            {console.log(LoginUser, '......')}
            {LoginUser.ROLE === '0' && !isEditing ? (
              <Flex className="">
                <Button onClick={onEdit}>
                  Edit
                </Button>
              </Flex>
            ) : isEditing ? (
              <Flex >
                <Flex gap={20} >
                  <Button htmlType="submit" form="profile_form">
                    Submit
                  </Button>
                  <Button onClick={onCancel} type="default">
                    Cancel
                  </Button>
                </Flex>
              </Flex>
            ) : null}
          </div>
          {/* </div> */}
          <div ref={uploadContainerRef}>
            <Upload
              name="userPhoto"
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Prevents auto upload
              onChange={handleChange}
              showUploadList={false} // Hide default upload list
            >
              <Button icon={<UploadOutlined />} style={{ display: "none" }}>
                Click to upload
              </Button>
            </Upload>
          </div>
        </Flex>

      </Flex>
      <Form
        form={form}
        name="profile_form"
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
        disabled={!isEditing}
        encType="multipart/form-data"
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              className="Profile-FormItem"
              name="firstname"
              label="First Name"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input
                className="Profile-Form-Input"
                placeholder="Enter your first name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="Profile-FormItem"
              name="lastname"
              label="Last Name"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input
                className="Profile-Form-Input"
                placeholder="Enter your last name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              className="Profile-FormItem"
              name="dateofbirth"
              label="Date of Birth"
              rules={[
                {
                  required: true,
                  message: "Please select your date of birth!",
                },
              ]}
            >
              <DatePicker
                className="Profile-Form-Input"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="Profile-FormItem"
              name="weight"
              label="Weight (kg)"
              rules={[
                { required: true, message: "Please input your weight!" },
              ]}
            >
              <Input
                className="Profile-Form-Input"
                type="number"
                placeholder="Enter your weight"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              className="Profile-FormItem"
              name="height"
              label="Height (cm)"
              rules={[
                { required: true, message: "Please input your height!" },
              ]}
            >
              <Input
                className="Profile-Form-Input"
                type="number"
                placeholder="Enter your height"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="Profile-FormItem"
              name="gender"
              label="Gender"
              rules={[
                { required: true, message: "Please select your gender!" },
              ]}
            >
              <Radio.Group>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Col>
  </Row>

  );
};

export default ProfileForm;
