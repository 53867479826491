// import React from 'react';
// import './css/QuestionAnswerComponent.css'; // Import the CSS file
// import config from '../../config';
// const QuestionAnswerComponent = ({ question, answer }) => {
//   const isImage = (answer) => {
//     const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
//     return imageExtensions.some((ext) => answer.toLowerCase().endsWith(ext));
//   };

//   return (
//     <div className="question-answer-container">
//       <div className="question">Q:{question}</div>
//       <div className="answer">
//         {isImage(answer) ? (
//           <img src={`${config.imageUrl}${answer}`} alt="Answer" />
//         ) : (
//           <p>A: {answer}</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuestionAnswerComponent;


import React from 'react';
import './css/QuestionAnswerComponent.css'; // Import the CSS file
import config from '../../config';
const QuestionAnswerComponent = ({ question, answer }) => {
  const isImage = (answer) => {
    const text =answer?answer:''
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
    return imageExtensions.some((ext) => text.toLowerCase().endsWith(ext));
  };

  const isVideo = (answer) => {
    const text =answer?answer:''
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some((ext) => text.toLowerCase().endsWith(ext));
  };

  return (
    <div className="question-answer-container">
      <div className="question">{question?question:''}</div>
      <div className="answer">
        {isImage(answer) ? (
          <img src={`${config.imageUrl}${answer}`} alt="Answer" />
        ) : isVideo(answer) ? (
          <video className='Question-Answer-Video' controls>
            <source src={`${config.imageUrl}${answer}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>{answer?answer:""}</p>
        )}
      </div>
    </div>
  );
};

export default QuestionAnswerComponent;
