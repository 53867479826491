import React, { useEffect, useState } from "react";
import "./css/Home.css"; // Make sure to create this CSS file for styling
import UserDisplayCard from "../Components/cards/UserDisplayCard";
import CurrentServicesCard from "../Components/cards/CurrentServicesCard";
import New from "../Components/home/New";
import Assigned from "../Components/home/Assigned";
import UserProfile from "./UserProfile";
import axios from "axios";
import config from "../config";

import { useNavigate } from "react-router-dom";
const Home = () => {
  const [showprofile, setShowProfile] = useState(true);
  const [SelectUserData, setSelectUserData] = useState([]);
  const [AssignedStyle, setAssignedStyle] = useState([]);

  const fcmToken = sessionStorage.getItem("fcmToken");
  const navigate = useNavigate();
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  console.log(LoginUser, "LoginUser");
  const AssignedUserData = (SelectUserData) => {
    console.log(SelectUserData, "ksdnihsifhisailn");
    setSelectUserData(SelectUserData);
    setShowProfile(!showprofile);
  };

  const CloseRecordNotifyBackend = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Your Service has been Closed",
          body: "Skanda Stylist",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
        // navigate("/dashboard");
        setShowProfile(true);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const CloseRecordNotify = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Your Service has been Closed",
          body: "Skanda Stylist",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
        // navigate("/dashboard");
        setShowProfile(true);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  const CloseRecord = async (data) => {
    console.log(data, "sidjshisil....", {
      STYLIST_ID: LoginUser?.USER_ID,
      USER_ID: SelectUserData?.user_id.toString(),
      STATUS: "4",
      USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
    });
    try {
      const response = await axios.post(
        `${config.baseURL}service_status`,
        new URLSearchParams({
          STYLIST_ID: LoginUser?.USER_ID.toString(),
          USER_ID: SelectUserData?.user_id.toString(),
          STATUS: "4",
          USER_SERVICES_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
        // navigate("/dashboard");
        CloseRecordNotifyBackend();
        CloseRecordNotify();
        setShowProfile(true);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  return (
    <div className="home-container">
      {showprofile ? (
        <div className="heading-section">
          {LoginUser.ROLE === "0" && (
            <New AssignedSylzData={(data) => setAssignedStyle(data)} />
          )}
          <Assigned
            SelectUserData={(SelectUserData) =>
              AssignedUserData(SelectUserData)
            }
            AssignedStyle={AssignedStyle}
          />
        </div>
      ) : (
        <>
          <UserProfile
            SelectUserData={SelectUserData}
            setBack={() => setShowProfile(!showprofile)}
            CloseRecord={CloseRecord}
          />
        </>
      )}
    </div>
  );
};

export default Home;
