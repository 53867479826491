// import React from "react";
// import "./css/CurrentServicesCard.css";
// const CurrentServicesCard = ({
//   imageSrc,
//   dob,
//   height,
//   buttonText,
//   onButtonClick,
//   bgColor,
//   name,
// }) => {
//   return (
//     <div className="current-services-card" style={{ backgroundColor: bgColor }}>
//       <div className="content">
//         <div className="contentimage">
//           <img src={imageSrc} alt="Profile" className="profile-image" />
//           <span className="dob"> {name}</span>
//         </div>
//         <div className="contentdobheight">
//           <span className="dob"> {dob}</span>
//           <span className="height"> {height}</span>{" "}
//         </div>
//       </div>
//       <button className="CurrentServicesButton" onClick={onButtonClick}>
//         {buttonText}
//       </button>
//     </div>
//   );
// };

// export default CurrentServicesCard;
import React from 'react';
import "./css/UserDisplayCard.css"
import config from '../../config';
import Profile from '../../images/profile.jpg'
const CurrentServicesCard = ({ image, name, dob, purchasedate, gender, category, buttonText, background, onClick, user }) => {
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);
 
    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }
 
 
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Handle case where dateString might be null or undefined
 
    const date = new Date(dateString);
 
    // Check if the date is valid
    if (isNaN(date.getTime())) return 'Invalid Date';
 
    // Array of month abbreviations
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
    const day = date.getDate();
    const month = monthNames[date.getMonth()]; // Get the 3-letter month abbreviation
    const year = date.getFullYear();
 
    // Format the date as Day 3-Letter Month, Year
    return `${day < 10 ? '0' : ''}${day} ${month}, ${year}`;
  };
 
 
  function formatDateToDDMMYY(isoString) {
    const date = new Date(isoString);
    console.log(user, 'skdusauhdihk........')
    // Get day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
 
    // Return formatted date
    return `${day}/${month}/${year}`;
  }
  return (
    <>
 
      <div className="user-display-card" style={{ background: `${background}` }}>
        {/* <div className='User-Disaplay-Card-Container-Header'><p>hi</p></div> */}
        <div className="first-div">
          <div className="image-container">
            <img src={`${image ? extractBaseUrl(`${config.imageUrl}${image}`) : Profile}`} alt="User" />
          </div>
          <div className="info-container">
            <div className="info-item-container">
              <div className="info-item">
              {name?name:'No Name'}
                <div className="info-itemdob">
                  <div>
                    Purchased Date:
                  </div>
                  <div className='User-Purc-Date-Div'>
                    {purchasedate ? formatDate(purchasedate) : user?.enrollment_date ? formatDate(user?.enrollment_date) : ''}
                  </div>
                </div>
              </div>
              <div className="info-itemdob-gender">
                <div>
                  Gender :
                </div>
                <div className='User-Purc-Date-Div'>
                {gender}
                </div>
              </div>
              <div>
                {category}
              </div>
            </div>
            <div className="button-container">
 
              <button onClick={onClick}>{buttonText}</button>
            </div>
          </div>
 
 
        </div>
 
      </div>
    </>
  );
};
 
export default CurrentServicesCard;
 